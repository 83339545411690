.search-area {
    position: fixed;
    width: 0;
    height: 0;
    overflow: hidden;
    top: 0;
    right:0;
    z-index: 10;
    background-color: rgba($color: $main-dark, $alpha: 0.9);
    form {
        z-index: 12;
        width:60%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &__input {
        height: 50px;
        background-color: transparent !important;
        border: unset !important;
        width:100% !important;
        color: $white !important;
        &::placeholder {
            color: $white;
        }
        letter-spacing: 2px;
        @include fontface($font-secondary,30px,$white,normal);
        border-bottom: 2px solid rgba($color: $white, $alpha: 0.3) !important;
    }
    &__submit {
        margin-top: 5px;
        cursor: pointer;
        float: right;
        border:none;
        background-color: transparent;
        span {
            text-transform: uppercase;
            @include fontface($font-secondary,13px,$white,normal);
        }
    }

    &__close {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 11;
    }

}
.search-bar-show {
    width: 100%;
    height:100%;
}