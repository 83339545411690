.blog-sidebar {
    margin-top: 65px;
    &__widget {
        @extend .destination-sidebar__widget;
    }
    .sidebar-category {
        ul {
            margin: 0;
            li {
                list-style-type: none;
                a {
                    display: flex;
                    justify-content: space-between;
                    color: $dusty-gray;
                    &:hover {
                        color: $turquoise;
                    }
                }

            }
        }
    }
    .recent-post {
        &__item {
            margin-bottom: 5px;
            img {
                width: 85px;
                height: 85px;
                border-radius: 5px;
            }
            &__info {
                padding: 15px 0 15px 15px;
            }
            display: flex;
            align-items: center;
            h5 {
                margin: 0 0 3px 0;
                @include fontface($font-primary-medium,15px,$main-dark,normal);
            }
            span {
                color: $dusty-gray;
            }
        }

    }

}


