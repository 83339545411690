//------------------------------ SPECIAL TUORS POSPONSIVE
@media (min-width:1200px){
    .special-tour__sub-tittle {
        width: 270px;
    }
}
.special-tour-slider__item {
    .trending-tour-item {
        
        @media(min-width:560px) and ( max-width: 768px ) {
            .trending-tour-item__group-infor {
                &__sale-price,&__price {
                    font-size: 20px;
                }
            }
        }

    }
    @media (min-width:450px)and(max-width:559px) {
        .trending-tour-item {
            width: 75%;
            display: inline-block;
        }
        display: flex !important;
        justify-content: center;
        
    }
    
}
@media(max-width:$md) {
    #special-tour {
        padding: 30px 0 50px;
    }
}
@media(max-width:$md) {
    #special-tour {
        .slick-dots {
            width: 100%;
        }
    }

}

//------------------------------ COL-3-TOUR RESPONSIVE
#col-3-tours {
    .trending-tour-item {
        @media(min-width:400px) and ( max-width: 575px ) {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
        }
    
        @media(min-width:576px) and ( max-width: 767px ) {
            &__group-infor {
                &__price,&__sale-price {
                    font-size: 20px;
                }
            }
        }
    }
    @media(min-width:$sm)and(max-width: $md){
        .trending-tour-item__name {
            font-size: 15px;
        }
        .trending-tour-item__group-infor__price {
            top: 85px;
        }
    }
}

//------------------------------ END COL-3-TOUR RESPONSIVE

//------------------------------ COL-4-TOUR RESPONSIVE--------------------------------------
@media (min-width:$md) {
    .col-4-tour__sub-tittle {
        display: inline-block;
        width: 370px;
    }
}
@media (min-width:$xl) {
    .col-4-tour {
        .trending-tour-item__thumnail {
            height: 270px;
        }
    }

}
@media (max-width:$sm) {
    .col-4-tour {
        .trending-tour-item {
            width: 80%;
            margin-left: auto;
            margin-right: auto;
        }
    }

}


//------------------------------ END COL-4-TOUR RESPONSIVE

//------------------------------ DEAL TOUR RESPONSIVE--------------------------------------


.deal-tour {
    @media(max-width:1620px){
        &__item__countdown-sale {
            bottom: 8% !important;
 
        }
    }
    @media(max-width:$xl){

        &__item__countdown-sale {
            &__time {
                width: 80px;
                height: 80px;
            }
            span {
                font-size: 30px;
            }
            p {
                font-size: 15px;
                margin: 0;
            }
        }

    }
    
    @media (max-width:$lg){
        grid-template-columns: 60% 40%;
        grid-template-rows: auto auto;
        &__item {
            &__info {
                span {
                    font-size: 15px;
                }
            }
            &--largest {    
                max-height: 600px;
                height: 100%;  
                grid-row-start: 1;
                grid-row-end: span 1;  
            }
            &--middle {
                order: 3;
            }
        }

    }
    @media(max-width:$md){
        padding: 0 15%;
        grid-template-columns: 100%;
        grid-auto-rows: auto auto auto;

        &__item {   
            &__countdown-sale {
            
                li {
                    width: 80px !important;
                    height: 80px !important;
                    span {
                        font-size: 30px;
                    }
                }
            }
            &--largest {    
                max-height: 500px;
             
            }

            &--vertical {

                img {
                    max-height: 500px;
                    object-fit: cover;
                }
            }
            &--middle{
                order: unset;
            }
        }
        
    }
    @media (max-width:$sm){
        padding: 0;
        &__item {

            &__countdown-sale {

                &__time {
                    width: 65px;
                    height: 65px;
                }
                span {
                    font-size: 20px;
                }
                p {
                    font-size: 13px;
                    margin: 0;
                }
                &__countdowndot {
                    font-size: 40px;
                    margin: 0 5px;
                   
                }
            }
            
            &--vertical {
                order: 3;
                img {
                    max-height: 400px;
                    object-fit: cover;
                }
                
            }
            &--largest {    
                max-height: 450px;
             
            }
        }
    }

    @media (max-width:475px) {
    &__item {
        &__info {
            padding: 5px;
            span {
                font-size: 14px;
            }
            p {
                margin: 0;
            }
        }
    }
    }

}


//------------------------------ FLTERABLE TOUR RESPONSIVE--------------------------------------
@media(min-width:1900px){
    .filterable-tour {
        padding-top:1px;
    }
}
@media(max-width:$sm){
    .filterable-tour {
        padding-bottom:10px;
    }
}


//------------------------------ BESTSELLING TOUR RESPONSIVE--------------------------------------
@media(min-width:$md){
    .best-selling-tour {
        padding-bottom: 60px;
        
    }
}
@media(max-width:$md) {
    
    .best-selling-tour__item {
        justify-content: center;
    }
}
@media(max-width:$sm) {
    .best-selling-tour {
        h2 {
           font-size: 30px; 
        }
        
    }
}


//------------------------------ GRID-LEFT-SIDEBAR RESPONSIVE--------------------------------------
@media(min-width:$md)and(max-width:991px){
    .grid-left-sidebar__filter {
    
      display: block;
        height: 76px;
    }
    .grid-left-sidebar__filter--mode {
        margin-top: 10px;
    }
}
@media(max-width:$sm){
    .grid-left-sidebar__filter {
    
        display: block;
        height: 76px;
      }
    .grid-left-sidebar__filter--mode {
        margin-top: 10px;
        .custom-select {
            width: 180px;
        }
    }
    .grid-left-sidebar {
        padding: 50px 0;
    }
}




//------------------------------ LIST-LEFT-SIDEBAR RESPONSIVE--------------------------------------


.list-left-sidebar{
    @media (min-width:$md)and(max-width:$lg){
        &__grid {
            grid-template-columns: 60% auto;
            grid-template-rows: auto auto;
        }
        .trending-tour-item__info {
            order: 2;
            grid-column-end:span 2;
        }
        .trending-tour-item__group-infor--left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .trending-tour-item__group-infor__lasting {
                margin: 0;
            }

        }
    }

    @media (max-width:$md){
        .list-left-sidebar__price {
            .trending-tour-item__group-infor__price {
                font-size: 20px;
            }
            .list-left-sidebar__price__view-detail {
                width: 80px;
                height:30px;
                line-height: 30px;
                font-size: 10px;
            }
        }
    }
    @media (max-width:$sm) {
        .list-left-sidebar__tour-descript {
            display: none;
        }
        &__grid {

            grid-template-columns: 70% auto;
            grid-template-rows: auto auto;
        }
        .trending-tour-item__thumnail {
            grid-column-end: span 2;
            
            max-width: 100% ;
            
        }
        .list-left-sidebar__price__view-detail {
            display: none;
        }
    }
}





//------------------------------ TOUR INFOMATION RESPONSIVE--------------------------------------

@media(max-width:$xl){
    .tour-infomation__content {
        padding-right: 0;
    }
    .tour-infomation__content__review {
        padding:15px ;
    }
}

@media(max-width:$md){
    .tour-infomation__content__review {
        padding:15px ;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        row-gap: 20px;
    }
    .tour-infomation__content__header__icon {
        grid-template-columns: auto auto;
    }
}

@media(max-width:$sm){
    .tour-infomation__content__review__rate-list {
       li div {
           span {
               &:nth-child(2){
                   width: 50%;
                   @media(max-width:420px){
                    width: 30%;
                    }
               }

           }
       }
    }
    .tour-infomation__content__header__icon {
        grid-template-columns: 100%;
    }
}





