@include sectionTittleMiddle (destination-5);

.destination-5 {

    &__item {  
        img {
            vertical-align: middle;
        }
        height: 348px;
        &:hover {
            padding: 15px 0;   
            span {
                margin: 0;
            }         
        }
        margin-bottom: 50px;
        text-align: center;
        display: block;
        span {
            transition: all 0.4s;
            display: block;
            text-transform: uppercase;
            @include fontface($font-primary-bold,17px,$main-dark,normal);
        }
        &__below-tittle {
            margin-top: 20px;
        }
        &__upper-tittle {
            margin-bottom: 20px;
        }
    }
}

