.tour-item-aside-h2 {
    display: none;
}
.tour-infomation__content {

    padding:60px 30px 0 0;
    p {
        margin: 0;
        color: $dove-gray;
    }
    span {
        font-weight: 600;
        color: #333333;
    }
    h2 {
        margin-top: 45px;
        @include fontface($font-primary-medium,23px,$main-dark,normal);
    }
    &__header {
        border-bottom: 1px solid #e6e6e6;
        h2 {
            font-size: 30px;
            margin: 0 0 8px;
        }
        span {
            color: $dusty-gray;
            margin-right: 13px;
        }

        &__icon {
            display: grid;
            grid-template-columns: 46% auto;
            grid-auto-rows: 45px;
            align-items: center;
            padding: 25px 0;
            p {
                display: flex;
                align-items: center;
                color: #4c4c4c; 
                img {
                    margin-right: 20px;
                }               
            }
        }
    }

    &__descript {
        p {
            margin: 0 0 25px;
        }
        border-bottom: 1px solid #e6e6e6;
    }

    &__time-table {
        height: 50px;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        border-bottom: 1px solid #e6e6e6;

        &--price {
            @extend .tour-infomation__content__time-table;
            height: auto;
            align-items: unset;
            padding: 12px 0;
        }
        ul {
            margin: 0;
            li {
                padding-left: 3px;
                margin-left: 20px;
                list-style-image: url('../images/tours/tour_item-icon-tick.png');
            }
        }
        &__price-exclude {
            li{
                list-style-image: url('../images/tours/tour_item-icon-cross.png') !important;
            }
        }
    }

    &__activity-location {
        &__gmap {
            width: 100%;
            height: 400px;
            position: relative;
            .fa-map-marker-alt {
                position: absolute;
                left: 25%;
                bottom: 50%;
                font-size: 30px;
                color: #e80719;
            }
        }
        iframe {
            border-radius: 5px;
            width: 100%;
            height: 100%;
        }

    }

    &__schedule {
        &__day {
            border:1px solid #e6e6e6;
            cursor: pointer;
            padding: 12px 30px;
            transition: all 0.5s ;
            border-radius: 5px;
            margin-bottom: 10px;
    
            p {
                display: none;
                color: $dove-gray;
                padding-top: 15px;
    
            }
            .fa-angle-down {
                transition: all 0.5s;
                float: right;
                font-size: 23px;
            }
        }
        .schedule-show {
            @include boxShadow(0px 0px 14px 3px rgba(0, 0, 0, 0.1));
            margin-bottom: 20px;
            .fa-angle-down {
                @include Rotate(180deg);
            }
        
        }
    }

    &__FAQ {
        h2 {
            margin-bottom: 0;
        }
        &__item {
            cursor: pointer;
            padding:19px 0;
            transition: all 0.5s;
            .fa-angle-down {
                float: right;
                line-height: 24px;
                font-size: 23px;
            }
            p {  
                padding-top: 10px;
                display: none;
            }
            border-bottom: 1px solid #e6e6e6;
            .fa-angle-down {
                transition: all 0.5s;
            }
        }
        .faq-show {
            .fa-angle-down {       
                @include Rotate(180deg);
            }
        }
    }

    &__review {
        background-color: #f7f7f7;
        border-radius: 5px;
        display: grid;
        grid-template-columns: auto 73%;
        align-items: center;
        padding: 23px 60px 25px 43px;
        &__rate-point {
            h4 {
                margin: 0;
                @include fontface($font-primary-medium,50px,$turquoise,normal);
                span {
                    font-size: 30px;
                    color: $turquoise;
                }
            }
            h5 {
                margin: 0;
                color: $main-dark;
                font-family: $font-primary;
            }
        }

        &__rate-list {
            
            span {
                color: $main-dark;
            }
            ul {
                margin: 0;
            }
            li {
                display: flex;
                list-style-type: none;
                justify-content: space-between;
                
                div{
                    display: flex;
                    width: 70%;
                    justify-content: space-between;
                    align-items: center;
                }

            }
            &__rate-amount {
                width: 18px;
                display: inline-block;
            }
            &__process-bar {
                @include ReviewProcessbar(100%);
            }
            &__process-bar--60 {
                @include ReviewProcessbar(60%);
            }
            &__process-bar--20 {
                @include ReviewProcessbar(20%);
            }
            &__process-bar--0 {
                @include ReviewProcessbar(0%);
            }
        }
    }


}
@include sectionTittleMiddle(similar-tour);
.similar-tour {
    @extend .col-4-tour;
    padding-bottom: 50px;
}


