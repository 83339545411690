.destination-3 {


    &__figure {
        text-align: center;
        padding:60px 0 50px;
    }
    &__figure-item {
        display: block;
        padding: 0 50px;
        h5 {
            margin: 30px 0 15px;
            @include fontface($font-primary-bold,22px,$main-dark,normal);
        }
        p {
            color:$dove-gray;
            margin: 0;
        }
        img {
            transition: all 0.5s;
            filter: hue-rotate(0deg) saturate(0.0%) brightness(0.0%);
        }
        &:hover {
            img {
                filter: unset;
            }
        }
    }

    &__destination {
        padding-top: 56px;
        display: flex;
        
    }
    &__thumb
    {   
        & > img {
            vertical-align: middle;
        }
        text-align: center;
        &:hover {
            margin-top: -60px;
        }
        position: relative;
    }
    &__thumb-info {
        transition: all 0.5s;
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: -1;
        span{
            text-transform: uppercase;
            @include fontface($font-primary-bold,20px,$main-dark,normal);
        }
        p{
            margin: 0;
            @include fontface($font-primary,14px,$dove-gray,normal);
        }
    }
}




































