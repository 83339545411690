.slider-banner-2__item {
    & > img {
        vertical-align: middle;
    }
    position: relative;

    &__text {
        position: absolute;
        top:37%;
        transform: translate(0,-50%);
        span {
            @include fontface( $font-secondary,30px,#ffffff,normal);
        }
        h1 {
            text-transform: uppercase;
            @include fontface( $font-slider-banner-bold,75px,#ffffff,normal);
        }
        a {
            &:hover {
                font-family: $font-primary-medium;
                width: 175px;
                height: 50px;
            }
            color: #ffffff;
            width: 170px;
            height: 45px;
            border: 1px solid #ffffff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                margin-left: 10px;
            }
    
        }


        &__line {
            display: inline-block;
            width: 160px;
            border-top: #ffffff solid 3px;
            margin: 8px 20px;
        }
    }
}

//---------------- form -1 beneath
.index-3-form-area {
    margin-top: -170px;
    position: relative;
    background-color: $yellow;
    .form-area {
        background-color: $yellow;
    }
    .form__item {
        &--datepicker {
            &:hover {
                input {
                    border: 1px solid $main-dark;
                    color: $main-dark !important;
                }
                span {
                    color: $main-dark !important;
                }
            }
        }
        input:focus {
            border-color: $main-dark;
            color: $main-dark !important;

        }
        .custom-select:hover {
            border-color: $main-dark;
            color: $main-dark !important;
            .select-items li {
                border-color: $main-dark;
                color: $main-dark !important;
            }

        }
        input, span,.custom-select {
            color: $white !important;
            border: 1px solid $white;
        }
        .colorChange {
            color: $main-dark !important;
        }
        .far {
            border:none;
        }
        .select-items {
            background-color: $yellow;
        }
        .select-items li {
            background-color: $yellow;
            border-color: $white;
            
            &::before {
                background-color: rgba($color: $main-dark, $alpha: 0.2);
            }

        }
        &--submit {
            input {
                background-color: $white;
                color: $main-dark !important;
                &:hover {
                    color: $yellow !important;
                    
                }
            }

        }
        // .select-items li:hover, .same-as-selected {
        //     // background-color: rgba($color:$white, $alpha: 1.0) !important;
        // }
    }
}