.blog-list {
    padding: 65px 30px 100px 0;
    &__thumbnail {
        overflow: hidden;
        img {
            vertical-align: middle;
            transition: all 0.5s;
        }
    }
    &__info {
        padding: 30px;
        
    }
    &__item {
        display: block;
        margin-bottom: 50px;
        border-radius: 5px;
        overflow: hidden;
        border:1px solid #e6e6e6;
        &:hover {
            border:1px solid transparent;
            img {
                transform: scale(1.1);
            }
            @include boxShadow( -1px 19px 34px -10px rgba(0, 0, 0, 0.18));
        }
        h3 {
            @include fontface($font-primary-medium,23px, $main-dark,normal);
            margin-bottom: 15px;
        }
        span {
            color: $dusty-gray;
            margin-right: 20px;
            i {
                margin-right: 3px;
            }
        }
        p {
            color: $dove-gray;
            margin: 20px 0 0;
        }
    }
}
