.wander-pagination {
    &__pagination {
        display: flex;
        justify-content: center;
        &__paging {
            width: 38px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
            cursor: pointer;
            margin: 0 4px;
            color: $dusty-gray;
        }
        .current {
            background-color: $turquoise; 
            color: $white;
        }
    }
}
