.tour-infomation-2 {
    .tour-infomation__content__header {
        border: none;
    }
    .tour-infomation__content__header__icon {
        grid-template-columns: auto auto auto auto;
    }

    
    @media (max-width:$md){
        .tour-infomation__content__header__icon {
            grid-template-columns: auto auto ;
        }
    }
    @media (max-width:$sm){
        .tour-infomation__content__header__icon {
            grid-template-columns: auto;
        }
    }
}

