.header-content2 {

    height: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $main-dark !important;
    
    .header-2-nav {
 
        ul {
            height: 110px;
            margin: 0;
            list-style-type: none;
 
        }
        li {
            &:hover > ul {
                display: block;
            }
            height: 100%;
            position: relative;
            z-index: 5;
            display: inline-flex;
            align-items: center;
            a:not(.account) {
                color: $main-dark;
                font-family: $font-primary-medium;
                display: block;
                padding: 5px 2px;
                margin-left: 33px;
                border-bottom: 2px solid transparent;
                &:hover {
                    font-family: $font-primary-bold;
                    color: $yellow;
                    border-bottom: 2px solid $yellow ;
                }
            }
            ul {
                top: 110px;
                height: auto;
                position: absolute;
                background-color: $white;
                border-top: 3px solid $yellow;
                @include boxShadow(0px 4px 5px 2px rgba(0,0,0,0.15));
                margin: 0;
                li{
                    padding: 5px 0;
                    & > ul {
                        top: 0;
                        left: 100%;
    
                    }
                    width: 230px;
                    border-bottom: 1px solid #eeeeee;
                    &:last-child {
                        border-bottom: 1px solid transparent;
                    }
                    a:hover {
                        border-bottom: 2px solid transparent !important ;
                    }
                    a.marked2 {
                        border-bottom: 2px solid transparent !important ;
                    }
                }
                display: none;
            }
        }

    }

    &__call {
        color: $main-dark !important;
        transition: all 0.5s;
        img {
            display: inline-block;

        }
        &__phone-number {
            margin-left: 10px;
            p {
                margin-bottom: -5px;
                font-size: 17px;
            }
            span {
                font-family: $font-primary-bold; 
                font-size: 20px;
                color: $yellow;
            }
            display: inline-block;
        }
    }


    &__hamburger {
        
        @extend .header-content1__hamburger;
        span {
            background-color: $main-dark ;
        }
    }
}



