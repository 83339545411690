
.section-tittle {
    @media ( max-width: 767px ) {
        h2 {
            font-size: 50px;     
        }
        p{
            top: 20px;
        }
    }

    @media ( max-width: 550px ) {
        h2 {
            font-size: 30px;     
        }
        p,span {
            font-size: 20px;
            top: 10px;
        }
        &__line-under{
            width: 70px;
            border-top: 3px solid $turquoise;
        }

    }

}


