.contact-infomation {
    @media(max-width: $md){
        padding: 60px 0 60px;
        &__map {
            margin-top: 0;
            height: 250px;
        }
    }
    &__item--padding {
        @media(min-width:$lg){
            padding-left: 70px;
        }
    }
}
