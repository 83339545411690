.instagram {
    display: flex;
    &__item {
        display: block;
        position: relative;
        &::before {
            transition: all 0.5s ease;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            color: transparent;
            position: absolute;
            content: "\f16d";
            font-family: 'Font Awesome 5 Brands';
            font-size: 35px;
        }
        &:hover {
            &::before {
                color: $white ;
                background-color: rgba($color: #000000, $alpha: 0.5) ;
            }
        }
    }
    img {
        width: 100%;
        height: 100%;
    }
}
.instagram__tittle {
    display: none;
}