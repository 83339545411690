// Breakpoints

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Colors

$gallery: #eeeeee;
$white: #ffffff;


$yellow :rgb(255, 198, 0);
$main-dark :rgb(0, 0, 0);
$turquoise :#3fced3;
$dusty-gray: #999999;
$dove-gray: #666666;
$duck-gray:#656565;
$color-primary: #000000;
$color-secondary: #aa2231;

$background-header: #211d1c;

// Fonts


$font-primary: Lato-regular;
$font-primary-medium: Lato-700;
$font-primary-bold:Lato-900;
$font-primary-light: Lato-300;

$font-secondary: 'Ubuntu-regular', sans-serif;
$font-secondary-medium:'Ubuntu-500', sans-serif;
$font-secondary-bold:'Ubuntu-bold', sans-serif;
$font-secondary-light:'Ubuntu-300', sans-serif;

$font-slider-banner-reg: 'Poppins-regular';
$font-slider-banner-bold: 'Poppins-700';

$font-testi: 'Birdrocker';
$font-video-tittle:'Racing Sans One';
$awe: "FontAwesome";


