#footer-4 {
    background-color: #1a1a1a;
    .footer-menu {
        a {
            color: $dusty-gray;
            &:hover {
                color: $yellow;
            }
        }
    }
    .copyright {
        position: unset;
    }
}