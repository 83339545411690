.footer-2 {
    padding-top: 130px;
    background-color: #1a1a1a;
    .copyright {
        &__area {
            border: none;
        }
        &__link{
            font-size: 13px;
            color: $dove-gray;
            text-transform: uppercase;
            margin-left: 20px;
        }
        &__social {
            a:hover {
                color: $white;
                font-family: $font-primary-medium;
            }
        }
        border-top: 1px solid #424547;
        position: unset;
    }
}
.footer-2__widget {
    margin-bottom: 65px;
    text-align: center;
    &__tittle {    
        h5 {
            color: #ffffff;
            text-transform: uppercase;
            font-size: 18px;
            margin: 0;
            letter-spacing: 2px;
        }
        &__line-under {
            display: inline-block;
            width: 50px;
            height: 12px;
            border-top: 3px solid $turquoise;
        }
    }
    &--left {
        padding-right: 70px;
    }
    &--right {
        padding-left: 70px;
    }
    &__social {
        margin-top: 25px;
        &-item {
            align-self: center;
        }
        display: grid;
        grid-template-rows: 60px 80px;
        grid-template-columns: auto auto auto;
    }


    &__map {
        img {
            transition: all 0.5s;
        }
        &:hover {
            img {
                filter: brightness(120%);  
            }
        }
        position: relative;
        margin-bottom: 5px;
        .fa-map-marker-alt {
            content: "\f3c5";
            font-family: 'Font Awesome 5 Free Solid';
            position: absolute;
            color: $yellow;
            font-size: 20px;
            right: 20%;
            bottom: 35%;
        }
        &__address {
            @include fontface($font-primary-light,15px,$turquoise,normal);
        }
    }

    &__form{
        margin-top: 45px;
    }


}