.gallery {

    .slick-dots {
        z-index: 1;
        width: 370px;
        bottom:0;
        li {
            height: 5px;
            margin: 0;
            button {
                transition: all .5s ;
                width: 100%;
                padding: 0;
                height: 2px;
                background-color: $turquoise;
                &::before {
                    display: none;
                }
            }
        }
        .slick-active {
            & ~ li {
                button {
                    background-color: #ebebeb;
                }
            }
        }
    }

}

.gallery-control {
    box-shadow: 4px 16px 30px 11px rgba(0, 0, 0, 0.08);
    height: 150px;
    border-radius: 5px;
    background-color: #ffffff;
    position: relative;
    padding: 0 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -75px;
    span {
        @include fontface($font-primary-bold,22px,$main-dark,normal);
    }
    &__left {
        display: flex;
        align-items: center;
    }

    &__arrow {
        margin-left: 105px;
        width: 65px;
        display: inline-flex;
        justify-content: space-between;
        span {
            cursor: pointer;
            color:#cccccc;
            &:hover {
                color: $yellow;
            }
            font-size: 30px;
        }
        
    } 
    
    &__counter {
        display: inline-block;
        span {
            @include fontface($font-primary-medium,22px,#cccccc,normal);
        }
        .gallery-control__counter--current {
            color: $main-dark;
        }
        &--cross {
            margin: 0 7px;
        }
    }
}
