.deal-tour {
    display: grid;
    grid-template-columns: 33.67% 44.27% auto;
    grid-template-rows: 50% 50% ;
    justify-items: stretch;
    align-items: stretch;
 
    &__item {
     
        img {
            transition: all 0.5s;
        }
        &:hover:not(.deal-tour__item--largest){
            img {
                transform: scale(1.1);
                filter: brightness(50%);
                -webkit-filter: brightness(50%);
            }
        }
        overflow: hidden;
        display: block;
        color: $main-dark !important;
        position: relative;
        &__info {
            width: 100%;
            padding: 0 25px;
            left: 50%;
            transform: translate(-50%,0);
            position: absolute;
            bottom: 0;
            text-align: center;
            p {
                vertical-align: middle;
                color: $white;
                i {
                    margin-left: 5px;
                    
                }
            }
            span {
                text-transform: uppercase;
                @include fontface($font-primary-medium,20px,$white,normal);
            }
        }
        &__countdown-sale {
            position: absolute;
            bottom: 16%;
            left: 50%;
            
            transform: translate(-50%,-50%);
            width: 100%;
            text-align: center;
            &__countdowndot {
                display: inline-block;
                vertical-align: text-bottom;
                font-family:Arial, Helvetica, sans-serif;
                color: #ffffff;
                font-size: 50px;
                margin: 0 10px;
      
            }
            &__time {
                display: inline-flex;
                align-items: center;
                background-color: #ffffff;
                border-top: 7px solid $turquoise;
                &:first-child {
                    display: none;
                }
                display: inline-block;
                width: 100px;
                height: 100px;
                text-align: center;
                
                span {
                    @include fontface($font-primary-bold,40px,$main-dark,normal);
                }
                p {
                    font-size: 18px;
                    margin-top: -12px;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
        }
        
        &--largest {
            grid-row-start: 1;
            grid-row-end: span 2;  
        }
        &--vertical {
            overflow: hidden;
            grid-row-end: span 2;
        }
        //middle block
        &--middle {
            grid-row-end: span 2;
            display: grid;
            grid-template-columns: auto auto;
            grid-template-rows: 50% 50%;

        }
        &--small {
            height: 100%;
        }
        &--horizontal {

            height: 100%;
            grid-row-start: span 2;
            grid-column-end: span 2;
        }
    }


}












