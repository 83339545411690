.gallery-syncing-item {
    margin-top: 25px;
    &__single {
        .slick-arrow {
            &::before {
                color: #b3b3b3;
            }
            width: 20px;
            display: block;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            z-index: 2; 
            font-size: 30px;  

        }
        .fa-angle-left {
            left: 30px;
        }
        .fa-angle-right {
            right: 30px;
        }
    }

    &__nav {
        &__item {
            img {
                margin: 0 auto;
                width: 95%;
            }
        }
        .is-active {
            img {
                filter: brightness(40%)
            }
        }
    }
}
@media(min-width:$xl){
    .gallery-syncing-item__nav__item {
        img {
            height: 140px;
            object-fit: cover;
        }
    }
}