
@media(max-width:$xl){
    .aboutus-descript__text {
        padding: 0;
    }
    .aboutus-descript__text--left {
        padding-bottom: 30px;
    }
}
@media (max-width:$lg){
    .aboutus-descript {
        padding-bottom: 0 ;
    } 
}
@media(max-width:$md){
    .aboutus-descript {

        h3 {
            font-size: 30px !important;
        }
    }

}
@media(max-width:$sm){
    .aboutus-descript__text {
        h5 {
            margin: 15px 0;
        }
    }
    .aboutus-descript__img {
        padding-top: 15px;
        flex-direction: column;
        img {
            margin: 30px auto 0 auto;
            width: 70% !important;
        }
    }
}