@include sectionTittle(travel-review);
.travel-review__sub-tittle {
    width: 270px;
    margin: 17px 0 0;
    @include fontface ($font-primary,13px,$dusty-gray,normal); 
}

#travel-review {
    position: relative;
    .worldBrg {
        top: -43%;
        left: 30%;
        width: 70%;
        z-index: -1;
    }
}

#travel-review-area {
    .slick-slide {
        padding: 0 15px;
    }
    .review-arrow-left {
        left: -5px;
        @include reviewArrowStyle();
    }
    .review-arrow-right {
        right: -5px;
        @include reviewArrowStyle();
    }
}
.travel-review {
    margin:60px 0;
    padding: 0 50px 40px;
    display: inline-flex !important;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
    @include boxShadow(0px 4px 10px 1px rgba(0,0,0,0.15));
    &__avatar {
        img {
            margin: -50% 0 18px;
            
        }
    }
    &__name {
        text-transform: uppercase;
        text-shadow: 0px 1px #a3a3a3;
        @include fontface ($font-primary,17px, $main-dark,normal);
    }
    &__career {
        margin: 5px 0;
        text-shadow: 1px 1px #e8e8e8;
        @include fontface ($font-primary,15px, $dusty-gray,normal);
    }
    &__rating {
        &::before {
            content: "\f005  \f005  \f005  \f005  \f005";
            font-family: $awe;
            color: $yellow;
        }
    }
    &__rating--4-star {
        &::before {
            content: "\f005  \f005  \f005  \f005  \f006";
            font-family: $awe;
            color: $yellow;
        }
    }
    &__content {
        margin: 20px 0 0;
        // text-shadow: 1px 1px #d4d4d4;
        @include fontface ($font-primary,18px, #666666,normal);
    }
}
.partner {

    img{
        transition: all .5s;
        -webkit-filter: saturate(0.1);
        filter: saturate(0.01);
        opacity: 0.33;
        &:hover {
            -webkit-filter: saturate(1);
            filter: saturate(1);
            opacity: 1;
        }
    }
    margin: 30px 0 90px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: wrap;

}