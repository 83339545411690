//------------------------- BLOG-LIST RPS--------------------
@media (max-width:$lg){
    .blog-list {
        padding: 65px 0 30px 0;
    }
}
@media (max-width:$sm){
    .blog-list__info {
        padding: 15px;
        h3 {
            font-size: 17px;
        }
        span {
            display: block;
        }
        p{
            margin-bottom: 10px;
        }
    }
}




//------------------------- BLOG-SNGLE ASIDE RPS--------------------


@media(max-width:$lg) {
    .blog-single-aside {     padding: 65px 0 30px; }


}
@media(max-width:$sm){
    .blog-single-aside {    
        &__content__img {
            margin-top: -30px;
            text-align: center;
            img {
                margin-top: 30px;
                width: 70%;
                
            }
        }
    }  
}