.preview-video-aboutus {
    position: relative;
    &__thumb {
        vertical-align: middle;
    }
    &__text-behind {
        margin-bottom: -80px;
        @include fontface($font-primary-bold,200px,#f7f7f7,normal);
    }
    .preview-video-aboutus__btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @extend .slider-banner-4__item__btn__img;
        &:hover {
            background-color: rgba($color: $white, $alpha: 0.3);
        }
    } 
}
@include PreViewVideo(preview-video-aboutus-popup);
