/* poppins-regular - latin */
@font-face {
    font-family: 'Poppins-regular';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/Poppins/poppins-v8-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Poppins Regular'), local('Poppins-Regular'),
         url('fonts/Poppins/poppins-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/Poppins/poppins-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/Poppins/poppins-v8-latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-700 - latin */
  @font-face {
    font-family: 'Poppins-700';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/Poppins/poppins-v8-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Poppins Bold'), local('Poppins-Bold'),
         url('fonts/Poppins/poppins-v8-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/Poppins/poppins-v8-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-700.woff') format('woff'), /* Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/Poppins/poppins-v8-latin-700.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-800 - latin */
  @font-face {
    font-family: 'Poppins-800';
    font-style: normal;
    font-weight: 800;
    src: url('fonts/Poppins/poppins-v8-latin-800.eot'); /* IE9 Compat Modes */
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
         url('fonts/Poppins/poppins-v8-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/Poppins/poppins-v8-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-800.woff') format('woff'), /* Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/Poppins/poppins-v8-latin-800.svg#Poppins') format('svg'); /* Legacy iOS */
  }
  /* poppins-900 - latin */
  @font-face {
    font-family: 'Poppins-900';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/Poppins/poppins-v8-latin-900.eot'); /* IE9 Compat Modes */
    src: local('Poppins Black'), local('Poppins-Black'),
         url('fonts/Poppins/poppins-v8-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('fonts/Poppins/poppins-v8-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-900.woff') format('woff'), /* Modern Browsers */
         url('fonts/Poppins/poppins-v8-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('fonts/Poppins/poppins-v8-latin-900.svg#Poppins') format('svg'); /* Legacy iOS */
  }