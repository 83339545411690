
.page-banner {
    display: flex;
    align-items: center;
    height: 200px;
    width: 100%;
    @include brgImg('../images/uploads/pagebanner/pagebanner.jpg');
    h1 {
        margin: 0;
        @include fontface($font-secondary-medium,40px,$white,normal);
    }
    p{
        color: $white;
        margin: 0;
        span {
            margin: 0 5px;
            color: $white;
            
            &:last-child {
                color: $turquoise;
                margin: 0;
            }
        }
    }
}