.subsriber-form-tittle {
    h2{
        margin-bottom: 5px;
        @include fontface ($font-secondary-bold,30px,#ffffff,normal);
    }
    text-align: center;
    
    span {
        @include fontface ($font-secondary,30px,#ffffff,normal);
    }
    &__line-under{
        width: 100px;
        display: inline-block;
        height: 55px;
        border-top: 5px solid #3fd0d4;
    }
}
.subsriber-form {

    text-align: center;
    &>div{
        display: inline-block;

    }

    &__text-input {
        height: 55px;
        padding: 20px 130px 20px 30px !important;
        color:$dusty-gray !important;
        &:focus {
            & + .subsriber-form__submit {
                border-top: 1px solid $main-dark;
                border-right: 1px solid $main-dark;
                border-bottom: 1px solid $main-dark;
            }
        }
    }
    &--padding {
        padding: 0 60px;
    }
    &__submit {
        cursor: pointer;
        position: absolute;
        top: 0;
        height: 100%;
        width: 120px;
        background-color: $yellow;
        border: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        right: 75px;
    }
}