.scroll-top {
    opacity: 0.5;
    position: fixed;
    bottom: -40px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: $yellow;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    &:hover {
        opacity: 1;
        box-shadow: -2px 2px 23px -6px rgba(0,0,0,1);
    }
    i {
        color: $white;
        font-size: 35px;
        margin-bottom: 3px;
    }
    &__showing {
        z-index: 2;
        bottom: 40px;
    }
}


//------------------------------------ header nav marker

.marked1 {
    background-color: $yellow;
    color: $main-dark !important;
}
.marked2 {
    font-family: $font-primary-bold !important; 
    color: $yellow !important;
    border-bottom: 2px solid $yellow !important;
}