table {
  border-collapse: collapse;
  margin: 0 0 1.5rem;
  /* Prevents HTML tables from becoming too wide */
  width: 100%;
}

thead th {
  border-bottom: 2px solid $dove-gray;
  padding-bottom: 0.5rem;
}

th {
  padding: 0.4rem;
  text-align: left;
}

tr {
  border-bottom: 1px solid $gallery;
}

td {
  padding: 0.4rem;
}

th:first-child,
td:first-child {
  padding-left: 0;
}

th:last-child,
td:last-child {
  padding-right: 0;
}
