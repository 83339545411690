#special-tour {
    position: relative;
    padding: 110px 0 100px;
}
#special-tour-container {
    margin-right: 0 !important;
}
.worldBrg {
    position: absolute;
    bottom: 0;
    left: 0;
}
.special-tour-slider__item {
    padding: 0 15px;
    &__excerpt {
        padding: 30px 0;
        @include fontface ($font-primary,15px,$dusty-gray,normal);
        transition: all 0.5s;
    }
    .trending-tour-item {
        @include boxShadow(0px 4px 10px 3px rgba(0,0,0,0.13));
    }
}

.special-tour {
    &__sub-tittle {
        margin-top: 25px;
        @include fontface ($font-primary,13px,$dusty-gray,normal);
        
    }

    .trending-tour-item {
        &__info {
            height: 147px;
            overflow: hidden;
            padding: 30px 20px 28px;
            transition: all 0.5s;
        }
        &__group-infor__lasting {
            img {
                float: left;
                margin-top: 3px;
            }
        }
        &__group-infor__sale-price{
            top: 57px;
        }
        &__group-infor__price{
            top: 80px;
        }
        
        &:hover {
            .trending-tour-item__info{
                height:231px;
                margin-top: -84px;
            }
            .special-tour-slider__item__excerpt {
                padding-top: 12px;
            }
        }
    }

}
