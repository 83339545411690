.blog-single-aside {
    padding: 65px 30px 100px 0;
    &__thumbnail {
        img {
            vertical-align: middle;
            border-radius: 5px;
        }
    }

    &__time {
        margin: 15px 0 27px;
        span {
            color: $dusty-gray;
            margin-right: 20px;
            i {
                margin-right: 3px;
            }
        }
    }

    &__content {

        &__img {
            padding: 45px 0 37px;
            img {
                vertical-align: middle;
            }
        }

        h3 {
            @include fontface($font-primary-medium,23px, $main-dark,normal);
            margin-bottom: 20px;
        }
        p {
            color: $dove-gray;
            margin: 7px 0 0 0;
        }
    }

    &__tag {
        padding: 40px 0 43px;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &__item {
            display: flex;
            align-items: center;
            span {
                margin-right: 7px;
                color: $main-dark;
            }
            p {
                cursor: pointer;
                margin: 0;
                color: $dusty-gray;
            }
            a {
                display: inline-flex;
                align-items: center;
            }
            img {
                margin:0 7px;
            }
        }
    }
}
