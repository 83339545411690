.qa-travel {
    height: 160px;
    width: 100%;
    @include brgImg('../images/q&abrg.png');
    &__center {
        display: flex;
        justify-content: center;
        height: 100%;
    }
    &__question {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: wrap;
        margin: 0 -15px;
        h6 {
            @include fontface($font-primary,30px,$main-dark,normal);
            margin: 0;
            span {
                font-family: $font-primary-medium;
            }
        }
        &__text {
            
            color: $main-dark !important;

            font-size: 30px;
        }
        &__btn {
            color: $main-dark !important;
            width: 135px;
            height: 45px;
            background-color: #ffffff;
            border-radius: 5px;
            text-transform: uppercase;
            text-align: center;
            line-height: 45px;
            &:hover {
                color: $yellow !important;
                font-family: $font-primary-medium;
            }
        }

    }
    @media(max-width:$xl){
        padding: 13px 0 20px;
        &__question {
            justify-content: space-around;
        }
    }
    @media(max-width:$md){
        &__question {
            &__text {
                
                font-size: 25px !important;
            }
            &__btn {
                height: 40px;
                line-height: 40px
            }
        }
    }
}

.footer-menu {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: 215px;
    grid-gap: 30px;
    &__item {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;
    }
    a {
        line-height: 50px;
        text-transform: uppercase;
        color: #4c4c4c;
        &:hover {
            color: $yellow;
            font-family: $font-primary-medium;
        }
    }
    &__logo {
        margin: 0 auto;
    }
    


}
.copyright-style3 {
    @extend .copyright;
    position: unset ;

    .copyright__payment-method {
        img {
            vertical-align: middle;
        }
        margin-left: 30px;
        &:first-child {
            margin: 0;
        }
    }
}


