@include sectionTittle(destination-4-2);
.destination-4-2__tittle {
    margin: 46px 0 15px;
}
.destination-4-2 {
    &__slider {
        margin: 0 -15px;
    }
    &__content {
        &__img {
            height: 68px;
            text-align: center;
        }
        padding: 25px;
        &__text {
            font-size: 14px;
            color: $dove-gray;
            margin-bottom: 10px;
        }
        box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        & > a {
            img {
                -webkit-filter: grayscale(100%) contrast(0.7);
                filter: grayscale(100%) contrast(0.7) opacity(0.7);
            }
        }
        & > a:hover {
            img {
                width: 25px;
                height: 15px;
                filter: unset;
            }
        }
    }

    &__arrow {
        cursor: pointer;
        color: #e6e6e6;
        position: absolute;
        top: -60px;
        font-size: 30px;
        transition: all .5s;
        &:hover {
            color: $turquoise;
        }
        &--left {
            right: 70px;
        }
        &--right {
            right: 15px;
        }
    }
    
}
.destination-4-2__item {
    img {
        display: inline-block;
    }
    margin: 25px 0 100px;
    text-align: center;
    padding: 0 15px;
    p{
        font-family: $font-primary-bold;
        color: $main-dark;
        text-transform: uppercase;
        margin: 25px 0 10px;
    }
}
