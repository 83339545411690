.slider-banner__item {
    & > img {
        vertical-align: middle;
    }
    position: relative;
    &__text {
        width: 100%;
        display: block;
        position: absolute;
        top: 80px;
        left: 0;

        h1 {
            margin-bottom: 10px;
            letter-spacing: 4px;
            @include fontface($font-secondary-medium,120px,$white,normal);
        }
        p {
            margin-bottom: 35px;
            width: 420px;
            display: block;
            @include fontface($font-primary,15px,$white,normal);
            span {
                font-family: $font-secondary-medium;
            }
        }
        a {
            @include fontface($font-primary,15px,$main-dark,normal);
            display: block;
            width: 130px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            border-radius: 5px;
            background-color: $yellow;
            &:hover {
                color: $white;
                font-weight: 600;
            }
        }

    }
}

.index-2-form-area {
    margin-top: -95px;
    .form-area {
        position: relative; 
        @include boxShadow (4px 26px 50px 11px rgba(0,0,0,0.12));
    }
}


