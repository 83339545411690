
.section-tittle {
	position: relative;
	p {
		top: 30px;
		position: absolute;
		@include fontface($font-secondary,30px,#000000,normal);
	}
	span {
		@include fontface($font-secondary-bold,30px,#000000,normal);
	}
	h2 {
		margin-bottom: 5px;
		text-transform: uppercase;
		@include fontface($font-secondary-bold,60px,#f2f2f2,normal);
	}
	&__line-under {
		position: absolute;
		width: 100px;
		border-top: 5px solid $turquoise;

	}

}