
@include sectionTittleMiddle(col-4-tour);

.col-4-tour {
    .trending-tour-item__name {
        font-size: 15px;
    }
    .trending-tour-item__group-infor__price {
        font-size: 20px;
        top: 70px;
    }
    .trending-tour-item__group-infor__sale-price {
        font-size: 15px;
    }
    .trending-tour-item__group-infor__lasting {
        margin-top: 10px;
        font-size: 13px;
    }
}
