.list-left-sidebar {
    .trending-tour-item {
        @include boxShadow (-1px 10px 30px 13px rgba(0, 0, 0, 0.10));
        &:hover {
            img {
                transform: unset;
            }
        }
    }
    &__grid {
        align-items: center;
        display: grid;
        grid-template-columns: 33.33333% 46% auto;
        grid-template-rows: auto;
    }
    &__tour-descript {
        margin: 0;
        color: $dusty-gray;
        margin-top: 15px;
        max-height: 72px;
        overflow: hidden;
    }
    &__price {
        justify-self: center;
        text-align: center;
        p,span {
            position: unset;
            display: inline-block;
        }
        &__discount {
            span {
                color: #b2b2b2;
                margin-right: 6px;
            }
        }
        &__view-detail {
            transition: all 0.3s;
            margin-top: 5px;
            color: $turquoise;
            border: 1px solid $turquoise;
            width: 100px;
            height: 40px;
            line-height: 40px;
            border-radius: 4px;
            font-size: 12px;
            text-align: center;
            &:hover {
                background-color: $yellow;
                color: $white;
                font-family: $font-primary-bold;
                border: unset;
            }
        }
    }
    .trending-tour-item__group-infor__price {
        font-size: 28px;
    }
    .trending-tour-item__thumnail {
        max-width: 270px;
    }
}
