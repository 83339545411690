
.footer-form {
    &__text-input {
        width: 100%;
        height: 45px;
        background-color: transparent !important;
        color: #e6e6e6 !important;
        border: 1px solid #e6e6e6 !important;
        padding-left: 22px !important;
    }
    &__submit {
        margin-top: 15px;
        height: 45px;
        width: 100%;
        background-color: $yellow;
        border: none;
        border-radius: 4px;
    }
}