
.gallery__syncing {
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba($color: #000000, $alpha: 0.8);
    
    &__close {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__area {
        position: relative;
        width: 70%;

    }
    &__btn-close {
        cursor: pointer;
        display: block;
        position: absolute;
        color: $white;
        font-size: 30px;
        font-weight: 600;
        right: -30px;
        top: -30px;
 
    }
    
    &__single  {
        .slick-arrow {
            &::before {
                cursor: pointer;
            }
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
            z-index: 1; 
            font-size: 50px;  
            color: $white;
        }
        .fa-angle-left {
            left: -30px;
        }
        .fa-angle-right {
            right: -30px;
        }
    }
    &__nav {


        .slick-prev {
            z-index: 1;
        }
        .slick-slide {
            cursor: pointer;
            margin: 5px;
            
        }
        .is-active {
            img {
                filter: brightness(40%)
            }
        }
    }
    .gallery-open {
        visibility: hidden;
    }
}


