///-------------- footer-1 reponsive


#remove-padding {
    
    @media ( min-width: 991px ){
        padding-left: 0px;
    }

}


@media ( max-width: 767px ){
    .footer-widget-1 {
        &--margin {
            margin-top: 50px;
            img {
                margin-bottom: 20px;
            }
        }
        &__text {
            p{
                margin-top: 0;
            }
        }
        margin-top: 0px;

    }
}
@media(max-width:$md) {
    #footer-1 {
        padding-top: 50px;
    }

}
@media ( max-width: 480px ){
    #footer-1 {
        height: 1180px;
    }
    .footer-widget-1--margin {
        text-align: center;
    }
    .footer-widget-1__lists {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .footer-widget-1__list {
            text-align: center;
            ul {
                margin: 10px 0 15px;
                li{
                    margin: 0 0 5px;
                }
            }
        }
        .footer-widget-1__tittle__line-under {
            display: inline-block;
            height: 30px;
        }

    }
    .copyright__area 
    {   
        padding-top: 7px;
        text-align: center;
        width: 100%;
        line-height: unset;
        display: inline-block;
        .copyright__license {
            margin-bottom: 5px;
        }
    }

    .copyright__social{
        img {
            margin:0 10px 0;
        }   
    }
}



///-------------- footer-2 reponsive


.footer-2 {
    @media ( max-width: $xl ){
        padding-top: 60px;
        
    }
    @media ( max-width: $md ){
        .copyright__area {
            height:80px;
            line-height: 40px;
            display: unset;
            text-align: center;
        }
        
    }
}
.footer-2__widget {
    @media ( max-width: $xl ){
        padding: 0px;
        
    }
    @media ( max-width: $md ){
        margin-bottom: 50px;        
    }
}



//-------------------------- footer -3 responsive



@media (max-width:$lg){
    .footer-menu {
        padding-top: 50px;
        grid-template-columns: auto auto;
        grid-template-rows: 100px 50px;
        grid-auto-flow: column;
        grid-row-gap: 0;
        margin-bottom: 30px;
        grid-column-gap: 50px;
        &__item:nth-child(1){
                grid-row-start: 2;
        }
        &__item:nth-child(2){
            grid-column-start: 1;
        }
        &__item:nth-child(3){
            grid-row-start: 2;
        }
    }
}
@media (max-width:$sm) {
    .qa-travel__question__text {
        font-size: 25px;
    }
}
@media (max-width:$md) {
    .footer-menu {
        grid-template-columns: auto;
        grid-template-rows: 70px 40px 40px;
        grid-auto-flow: unset;
        &__item:nth-child(3){
            grid-row-start: unset;
        }
    }

    .copyright-style3 {
        .copyright__area {
            text-align: center;
            height: 100px;
            line-height: 50px;
            display: block;
        }
        .copyright__payment-method {
            margin-left: 15px;
        }
        
    }
}