.header-content3 {
  
    height: 110px;
    &__mobile-logo {
  
        display: none;
    }
    &__logo {
        margin: 0 70px;
    }
    .header-3-nav {    
        height: 110px;  
        display: flex;
        justify-content: center;
        align-items: center;
        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            list-style-type: none;
            z-index: 2;
            height: 110px;
    
            transition: all 0.5s;

        }
        li {
            height: 100%;
            position: relative;
            display: inline-flex;
            align-items: center;

            
            &:hover > ul{
                display: block;
            }
            & > ul {
        
                top: 110px;
                height: auto;
                position: absolute;
                background-color: $white;
                border-top: 3px solid $yellow;
                @include boxShadow(0px 4px 5px 2px rgba(0,0,0,0.15));
                margin: 0;
                li{
                    padding: 5px 0;
                    & > ul {
                        top: 0;
                        left: 100%;
                    }
                    a {
                        
                        &:hover {
                            font-family: $font-primary-bold; 
                            color: $yellow;
                            border-bottom: 2px solid $yellow;
                        }
                    }
                    width: 220px;
                    border-bottom: 1px solid #eeeeee;
                    &:last-child {
                        border-bottom: 1px solid transparent;
                    }
                }
                display: none;
            }
        
            a:not(.header-content3__logo__sitename) {

                color: $main-dark;
                font-family: $font-primary-medium;
                display: inline-block;
                padding: 5px 2px;
                margin-left: 17px;
                margin-right: 17px;
                border-bottom: 2px solid transparent;

            }
        }
    }
    
    &__hamburger {
        @extend .header-content2__hamburger;
    }

}



