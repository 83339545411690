.tour-infomation__content__comment {
    padding: 50px 0 20px;
    border-bottom: 1px solid #e6e6e6;
    &__comment-item {
        margin-bottom: 30px;
        div{
            margin-left: 115px;
        }
        &__avatar {
            float: left;
        }
        &__timesheet{
            color: #b2b2b2 !important;
            font-size: 13px;
            margin-left: 25px;
            font-family: $font-primary-medium;
        }
        p {
            margin-top: 10px;
        }
    }
}

.tour-infomation__content__write-comment {
    p {
        margin-bottom: 5px;
    }
    textarea {
        height: 200px;
    }
    &__rating {
        margin-left: 15px;
  
        input[name="rate"]{
            cursor: pointer;
            visibility: hidden;
            position: relative;
            display: inline-block;
            &::before {
                position: absolute;
                content: "\f006";
                font-family: "FontAwesome";
                font-size: 14px;
                font-weight: 400 !important;
                color: $turquoise;
                bottom: -5px;
                left: 0;
                visibility: visible;
  
            }
            &::after {
                position: absolute;
                bottom: -5px;
                left: 0;
                content: "\f005";
                font-family: "FontAwesome";
                font-size: 14px;
                font-weight: 400 !important;
                color: $turquoise; 
            
            }

            &:checked ~ input {
                &::after {
                    visibility: hidden; 
                }
            }
        }
        .visible{
            &::after {
                visibility: visible; 
            }
        }
    }
    form {
        div {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 20px;
            margin-bottom: 20px;
        }
        input[type="submit"] {
            cursor: pointer;
            width: 100px;
            background-color: $yellow;
            border: none;
            border-radius: 4px;
            height: 45px;
            margin-top: 20px;
        }
    }
}