.aboutus-world-brg {
    @include brgImg('../images/abloutus/world.jpg');
    height: 755px;
    padding-top: 73px;
    .travel-review__sub-tittle {
        margin-bottom: 60px;
    }
}
.aboutus-status {
    .page-figure {
        border-bottom: unset;
    }
}
.travel-review-4 {
    .review-arrow-left {
        left: -5px;
        @include reviewArrowStyle();
    }
    .review-arrow-right {
        right: -5px;
        @include reviewArrowStyle();
    }
    .slick-dots{
        top:94%;
        li {
            width: 10px ;
            button {
                border: 1px solid $yellow;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                &::before {
                    display: none;
                }
            }
        }
        .slick-active {
            
            width: 26px;
            button {
                background-color:$yellow;
                width: 26px;
            }
            

        }
    }
    .slick-slide {
        padding: 0 15px;
    }


}


@media(min-width:$xl){
    .travel-review-4-area {
        max-width: 1230px;
    }
    .travel-review-4{
        .slick-slide {
            padding: 0 30px;
        }
    }

}
