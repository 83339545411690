//------------------------- RIGHT SIDEBAR RESPONSIVE----------------
@media (max-width:$sm) {
    .tour-infomation__content__write-comment form div {
        grid-template-columns:  auto;
        grid-template-rows: auto auto;
        row-gap: 20px;
    }
}
//------------------------- END RIGHT SIDEBAR RESPONSIVE----------------




//------------------------- LEFT SIDEBAR RESPONSIVE----------------

@media(max-width:$lg){
    .left-sidebar {
        padding-right: 0;
    }
}
@media(max-width:$md){
    .left-sidebar {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
}
@media(max-width:425px){
    .left-sidebar {
        width: 90%;
    }
}
@media(min-width:411px){
    .left-sidebar {
        .form__item {
            margin: 0;
        }
    }  
}
@media(max-width:411px){
    .left-sidebar {
        &__price-range {
            margin-top: 50px;
        }
    }  
}
//------------------------- END LEFT SIDEBAR RESPONSIVE----------------




//------------------------- DESTINATION SIDEBAR RESPONSIVE----------------

@media(max-width:$xl){
    .destination-sidebar {
        padding-left: 0;
    }

}
@media(max-width:$lg){
    .destination-sidebar {
        width: 70%;
        margin: 0 auto;
    }
}
@media(max-width:$md){
    .destination-sidebar {
        width: 80%;
    }
}

//------------------------- END DESTINATION SIDEBAR  RESPONSIVE----------------



//------------------------- BLOG SIDEBAR RESPONSIVE----------------

@media(max-width:$lg) {
    .blog-sidebar {
        width: 80%;
        margin: 30px auto 50px auto;
    }
}

//-------------------------END BLOG SIDEBAR RESPONSIVE----------------