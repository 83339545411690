.tour-item-banner {
    height: 500px;
    width: 100%;
    @include brgImg('../images/uploads/tours/tour_item.jpg');
    display: flex;
    
    align-items: flex-end;
    &__btn-area {
        display: flex;
        justify-content: flex-end;
    }

    &__btn {
        cursor: pointer;
        margin: 0 0 45px 15px;
        height: 45px;
        display: inline-flex;
        border-radius: 4px;
        width: 160px;
        color: $main-dark;;
        background-color: $white;
        align-items: center;
        justify-content: center;
        color: $main-dark;
        transition: all 0.5s;
        img {
            margin-right: 5px;
            transition: all 0.5s;
        }
        &:hover {
            background-color: $yellow;
            color: $white;
            img {
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
            }
        }
    }
}

