
.video-popup-1 {
    margin-top: 50px;
    &__head-Brg {
        @include brgImg('../images/uploads/video-popup/BrgMelbourne.jpg'); 
        height: 550px;
        position: absolute;
        width: 100%;
        z-index: -1;
    }

    &__tittle {
        img {
            margin: 48px 0 40px;
        }    
        text-align: center;
    }
    h2 {
        @include fontface($font-secondary-bold,60px,rgba($color: #ffffff, $alpha: 0.2),normal);        
    }

}

.video-popup-1__area {
    margin-top: -42px;
    position: relative;
}

.video-popup-img  {
    position: relative;
    img {
        vertical-align: middle;
        border-radius: 5px;
    }
}

#btn-popup {
    cursor: pointer;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: inline-block;
}

@include PreViewVideo(preview-video-1);
