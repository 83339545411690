@include sectionTittleMiddle (best-selling-tour);

.best-selling-tour {

    h2 {
        margin: 0;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        text-transform: uppercase;
        
        @include fontface($font-primary-bold,40px,#e5e5e5,normal);

    }

    &__item {

        img {
            transition: all 0.5s;
        }
        &:hover {
            img {
                transform: scale(1.1);
                z-index: 0;
            }

        }
        .trending-tour-item__sale {
            z-index: 1;
        }
        margin-bottom: 50px;
        display: flex;
        &__thumb {
            & > img {
                vertical-align: middle;
            }
            position: relative;
            overflow: hidden;
            border-radius: 4px;
        }
        &__info {
            position: absolute;
            width: 100%;
            bottom: 0;
            padding: 0 20px 17px;
            .trending-tour-item__name{
                width: 75%;
                margin: 0;
                color: $white;
            }
            p {
                margin: 0;
            }
        }
        .trending-tour-item__group-infor__sale-price {
            top: -3px;
            font-size: 15px;
        }
        .trending-tour-item__group-infor__price {
            top: 16px;
            font-size: 20px;
        }
    }


}


