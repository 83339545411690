.ui-icon-loading {
    background: url('http://code.jquery.com/mobile/1.1.0-rc.1/images/ajax-loader.png') !important;
    background-size: 46px 46px;
    width: 46px;
    height: 46px;
    transform: rotate(360deg);
    animation-name: spin;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }