.destination-mansory-layout
{
    &__item { 
        overflow: hidden;
        border-radius: 1%;
        img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
        }
        &:hover {
            & > img {
                transform: scale(1.1);
                filter: brightness(50%);
                -webkit-filter: brightness(50%);
            }
            .destination-mansory-layout__item__tour-info {
                padding: 22px 45px;
                &__line {
                    visibility: visible;
                    width: 35px;
                }
                span {
                    color: #ffffff;
                }
            }
            
        }
        position: relative;
        display: block;
        text-align: center;
        &__tour-info {
            top: 0;
            left: 0;
            transition: all 0.5s;
            padding: 22px 30px;
            position: absolute;
            p {
                @include fontface($font-primary-medium,20px,#ffffff, normal);
                text-transform: uppercase;
                margin-bottom: 5px;
            }
            span {   
                transition: all 0.5s;
                @include fontface($font-primary,15px,transparent, normal);
            }
            &__line {
                transition: all 0.5s;
                position: absolute;
                border-top: 3px solid #ffffff;
                width: 0px;
                left: 0;
                top: 37px;
            }
        }
    }
    margin-top: 65px;
    &__pagination {
        padding: 50px 0 100px;
    }
}

