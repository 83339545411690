.slider-banner-4 {

    &__item {
        & > img {
            vertical-align: middle;
        }
        position: relative;
        &__text-area {
            position: absolute ;
            width: 100%;
            top: 15%;
        }
        &__text {
            text-align: right;
            span {
                text-transform: uppercase !important;
                @include fontface( $font-secondary,30px,$white,normal);
            }
            h1 {
                margin-top: 20px;
                @include fontface($font-slider-banner-reg,75px, $white,bold);
            }
        }

        &__btn {
            position: absolute;
            bottom: 50px;
            left: 50px;
            display: flex;
            align-items: center;
            &:hover &__img{
                background-color: rgba(255, 255, 255, 0.25);
            }
            &__img {
                cursor: pointer;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 0.5s;
            }
            &__text {
                margin-left: 10px;
                line-height: 22px;
                @include fontface( $font-secondary,18px,$white,normal);
                span {
                    font-family: $font-secondary-medium;
                }
            }
        }
    }
    
    // video-popup
    .modal-box {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        iframe {
            
            position: absolute;
            width: 90%;
            height: 90%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .modal-close {
        top: 4px !important;
        right: 15px !important;
        z-index: 2;
        background-color: $white;
        color: $dusty-gray;
    }
}

@include PreViewVideo(preview-video-3);
@include PreViewVideo(preview-video-4);
