/* ubuntu-300 - latin */
@font-face {
  font-family: 'Ubuntu-300';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Light'), local('Ubuntu-Light'),
       url('fonts/Ubuntu/ubuntu-v14-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-300.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-300italic - latin */
@font-face {
  font-family: 'Ubuntu-300-italic';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Light Italic'), local('Ubuntu-LightItalic'),
       url('fonts/Ubuntu/ubuntu-v14-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-regular - latin */
@font-face {
  font-family: 'Ubuntu-regular';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Regular'), local('Ubuntu-Regular'),
       url('fonts/Ubuntu/ubuntu-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-regular.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-italic - latin */
@font-face {
  font-family: 'Ubuntu-regular-italic';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Italic'), local('Ubuntu-Italic'),
       url('fonts/Ubuntu/ubuntu-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500 - latin */
@font-face {
  font-family: 'Ubuntu-500';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Medium'), local('Ubuntu-Medium'),
       url('fonts/Ubuntu/ubuntu-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-500.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-500italic - latin */
@font-face {
  font-family: 'Ubuntu-500-italic';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Medium Italic'), local('Ubuntu-MediumItalic'),
       url('fonts/Ubuntu/ubuntu-v14-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-500italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700 - latin */
@font-face {
  font-family: 'Ubuntu-bold';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold'), local('Ubuntu-Bold'),
       url('fonts/Ubuntu/ubuntu-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-700.svg#Ubuntu') format('svg'); /* Legacy iOS */
}
/* ubuntu-700italic - latin */
@font-face {
  font-family: 'Ubuntu-italic';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/Ubuntu/ubuntu-v14-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Ubuntu Bold Italic'), local('Ubuntu-BoldItalic'),
       url('fonts/Ubuntu/ubuntu-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/Ubuntu/ubuntu-v14-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/Ubuntu/ubuntu-v14-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/Ubuntu/ubuntu-v14-latin-700italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}