@include sectionTittle(top-desti);

.top-desti {
    // &::after {
    //     content: '';
    //     width: 100%; /* Ensures there are at least 2 lines of text, so justification works */
    //     display: inline-block;
    // }
    // text-align: justify;
    // padding: 0 30px;
    display: flex;
    padding: 0 15px;

    &__item {
        margin: 0 15px;
        display: inline-block;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        &::after {
            transition: all 0.3s;
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 120px;
            background: linear-gradient(180deg, rgba(0,0,0,0) 15%, rgba(0,0,0,1) 100%) !important;
        }
        img{

            vertical-align: middle;
            transition: all 0.5s;

        }
        &:hover {
            img {
                transform: scale(1.1);
            }
            &::after {
                margin-top: -120px;
                
            }
            .top-desti__ammout {
                z-index: 1;
            }
        }

    }

    &__ammout {
        padding: 0 20px;
        width: 100%;
        bottom: 18px;
        display: flex;
        justify-content: space-between;
        position: absolute;

        span {
            i {
                margin-right: 5px;
                color: $yellow;
                font-size: 18px;
            }
            @include fontface ($font-primary,16px,#ffffff,normal);
        }
    }
}