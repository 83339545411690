.right-sidebar {
    padding-top: 65px;
    &__item {

        h3{
            display: block;
            height: 50px;
            line-height: 50px;
            text-align: center;
            width: 100%;
            margin: 0;
            background-color: $turquoise;
            @include fontface($font-primary-bold,25px,$white,normal);
            span {
                font-family: $font-primary;
                font-size: 17px;
                &:empty{
                    & {
                        display: none;
                    }
                    
                }
                &:nth-child(1){
                    margin-right: 23px;        
                }
                &:nth-child(2){
                    color: #ebebeb;
                    text-decoration: line-through;
                    margin-right: 9px;
                }
            }


        }
        &__form {
            background-color: #f7f7f7;
            padding: 5px 30px 50px;
            label {
                padding: 20px 0 7px;
                margin: 0;
                font-family: $font-primary-medium;
            }
            input {
                background-color: #f7f7f7;
                height: 40px;
                color: $dusty-gray;
            }
            &--date {
                position: relative;
                span {
                    position: absolute;
                    right: 20px;
                    top: 12px;
                    color: #b3b3b3;
                }
            }
            textarea {
                height: 150px;
                background-color: #f7f7f7;
            }
            input[type="submit"] {
                cursor: pointer;
                width: 100%;
                height: 40px;
                border: none;
                background-color: $yellow;
                color: $main-dark;
                margin-top: 25px;
                border-radius: 4px;
                &:hover {
                    color: $white;

                }
            }
        }


        &__contact {
            margin-top: 30px;
            height: 300px;
            width: 100%;
            padding: 32px 42px;
            @include brgImg('../images/sidebar/sidebar-contact-brg.jpg');
            h5 {margin-bottom: 15px;
                text-transform: uppercase;
                @include fontface($font-primary-medium,17px,$white,normal);
            }
            p{
                margin-bottom: 20px;
                font-size: 13px;
                font-family: $font-primary-medium;
                color: rgba($color: $white, $alpha: 0.5);
            }
            a {
                .contact-phone {
                    font-family: $font-primary-medium;
                }
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                img {
                    margin-right: 15px;
                }
                color: $white;
      
            }
        }
        
    }


}

