
.slider-banner-3 {

    &__item {
        position: relative;
        & > img {
            vertical-align: middle;
        }
    }
    &__item__text{
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0 15px;
        span {
            i {
                margin-right: 15px;
            }
            @include fontface($font-primary,30px,#a7b5be,normal);
        }
        h1 {
            text-transform: uppercase;
            @include fontface($font-primary-bold,70px,$white,normal);
        }
        top: 25%;
   
        position: absolute;
    }
}
.index-4-form-area {
    @extend .index-2-form-area;
    margin-top: -370px;
    position: relative;
    z-index: 1;
}