.aboutus-descript {
    padding: 60px 0 35px;
    h3 {
        margin-bottom: 47px;
        position: relative;
        @include fontface($font-primary-medium,40px,$main-dark,normal);
        &:before {
            content: "";
            position: absolute;
            width: 150px;
            height: 5px;
            background-color: $turquoise;
            bottom: -18px;
        }
    }
    p {
        color: $dove-gray;
        margin: 0;
    }

    &__img {
        padding: 45px 0;
        display: flex;
        justify-content: space-between;
        img {
            &:nth-child(1), &:nth-child(3){
                width: 26.823%;
            }
            &:nth-child(2){
                width: 43.23%;
            }
        }
    }

    &__text {
        &--left {
            padding-right: 100px;
        }
        &--right {
            padding-left: 100px;
        }
        &__img {
            height: 68px;
        }
        h5 {
            margin:35px 0 17px;
            font-family: $font-primary-medium;
        }
    }
}


