.t-center {
	text-align: center;
}
.t-left {
	text-align: left;
}
.t-right {
	text-align: right;
}
.t-white {
	color: $white;
}