
/// -------------------- HEADER-1- RESPONSIVE---------------:

@media ( max-width: $lg ){
	.header-1-nav {
		display: none;
	}
	.header-content1{
		position: relative;
		&__hamburger {
			display: block;
		}
	}

}
@media(min-width:$lg){
    .header-content1{
        .header-nav-mobile  {
            display: none !important;
        }
    }

}
#form-1-area  {
    @media ( max-width: 410px ){
        bottom: 5px;
    }
}

/// -------------------------------- END HEADER-1- RESPONSIVE-------------------------------:





/// -------------------------------- HEADER-2- RESPONSIVE-------------------------------:



@media(max-width:$xl){
    .header-2-nav,.header-content2__call {
        display: none;
    }
    .header-content2{
        position: relative;
        .header-content2__hamburger{
            display: block;
        }
    }

}
@media(min-width:$xl){
    .header-content2 {
        .header-nav-mobile{
            display: none !important;
        }
    }

}





/// -------------------------------- END HEADER-2- RESPONSIVE-------------------------------:





/// --------------------------------  HEADER-3- RESPONSIVE-------------------------------:

@media(max-width:$lg){
    .header-3-nav {
        display: none !important;
    }
    .header-content3{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &__mobile-logo {
            display: block;
        }
    }
}
@media(min-width:$lg){
    .header-content3 {
        .header-nav-mobile {
            display: none !important;
        }
    }

}

/// -------------------------------- END HEADER-3- RESPONSIVE-------------------------------:






/// -------------------------------- HEADER-4- RESPONSIVE-------------------------------:


.header-content2--style2 {
    @media(min-width:$lg){
        .header-2-nav {
            display: block;
        }
        .header-content2__hamburger  {
            display: none !important;
        }
    }
    @media(max-width: $xl){
        .header-content2--style2__account {
            margin-left: 30px !important;
        }

    }
}
@media(max-width:$md) {
    .header-4-upper__contact-area {
        display: none;
    }
    .header-4-upper__social {
        float: left;
    }
}

/// --------------------------------END HEADER-4- RESPONSIVE-------------------------------:
