.destination-sidebar {

    padding: 65px 0 50px 30px;
    &__searchbox {
        margin-bottom: 40px;
        position: relative;
        height:50px;
        line-height: 50px;
        input {
            height: 100%;
            width: 100%;
            color: #808080;
            font-family: $font-primary-medium;
            &:focus {
                border-color: $turquoise;
            }
        }
        border-radius: 5px;
        overflow: hidden;
        &__icon {
            height: 100%;
            top: 0;
            right: 0;
            border: none;
            cursor: pointer;
            position: absolute;
            width: 50px;
            background-color: $turquoise;
            img {
                vertical-align: middle;
            }
        }
    }

    &__widget {  
        h3 {
            display: block;
            text-transform: uppercase;
            margin: 30px 0 15px;
            font-family: $font-primary-bold;
            font-size: 17px;
        }
        p {
            margin-top: 5px ;
            color: $dove-gray;
            margin-bottom: 0px;
        }


        &__instagram {
            margin-top: -10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            &__item {
                border-radius: 5px;
                overflow: hidden;
                width: 74px;
                height: 74px;
                margin-top: 10px;
            }
        }

        &__tags {
            a {
                padding: 0 10px;
                height: 30px;
                line-height: 30px;
                display: inline-block;
                border-radius: 5px;
                border: 1px solid #e6e6e6;
                color: $dove-gray;
                margin: 0 7px 7px 0;
                transition: all 0.5s;
                &:active {
                    padding: 0 15px;
                    background-color: $yellow;
                    color: $main-dark;
                }
            }
        }
    }
}

