.header-content2--style2 {
    @extend .header-content2;

    &__account {
        display: inline-flex;
        margin-left: 80px;
        .account {
            cursor: pointer;
            img {
                vertical-align: middle;
            }
        }
        .header-search {
     
            margin-left: 20px;

        }
        .header-search-show {
            .header-search__bar {
                width: 200px;
                padding: 10px 15px;
            }
        }
    }
}

.header-4-upper {
    line-height: 50px;
    height: 50px;
    background-color: $yellow;
    &__contact-area-mobile{
        display: none;
    }
    &__contact {
        margin-right: 50px;
        color: $main-dark !important;
    img {
        margin: 0 10px -3px 0;
    }
    }
    &__social {
        img {
            margin-left: 20px;
        }
        float: right;
    }
    &__translater {
        margin-left: 50px;
        float: right;
        select {
            background-color: $yellow;
            border: none;
        }   
        
    }
}

