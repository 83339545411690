
.page-figure {
    border-bottom: 1px solid #e6e6e6;
    &__figure{
        height: 270px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__figure__text {
        text-align: center;
        span {
            @include fontface($font-primary-bold,60px,$yellow,normal);
        }
        p{
            margin-top: -5px;
            margin-bottom: 10px;
            @include fontface($font-primary-bold,22px,$main-dark,normal);
        }
    }
}


