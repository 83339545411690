
  @font-face {
    font-family: Lato-regular;
    font-style: normal;
    src: url("fonts/Lato/lato-v15-latin-regular.eot"),
     url("fonts/Lato/lato-v15-latin-regular.woff2") format("woff2"),
     url("fonts/Lato/lato-v15-latin-regular.woff") format("woff"), 
     url("fonts/Lato/lato-v15-latin-regular.ttf") format("truetype"), 
     url("fonts/Lato/lato-v15-latin-regular.svg") format("svg");
    /* Legacy iOS */ }

  @font-face {
    font-family: Lato-700;
    font-style: normal;
    src: url("fonts/Lato/lato-v15-latin-700.eot"),
         url("fonts/Lato/lato-v15-latin-700.woff") format("woff2"),
         url("fontsLato/lato-v15-latin-700.woff2") format("woff"), 
         url("fonts/Lato/lato-v15-latin-700.ttf") format("truetype"),
         url("fonts/Lato/lato-v15-latin-700.svg") format("svg");
    /* Legacy iOS */ }

    

  @font-face {
    font-family: Lato-300;
    font-style: normal;
    src: url("fonts/Lato/lato-v15-latin-300.eot"), 
    url("fonts/Lato/lato-v15-latin-300.woff2") format("woff2"), 
    url("fonts/Lato/lato-v15-latin-300.woff2") format("woff"), 
    url("fonts/Lato/lato-v15-latin-300.ttf") format("truetype"), 
    url("fonts/Lato/lato-v15-latin-300.svg") format("svg");
    /* Legacy iOS */ }

  @font-face {
    font-family: Lato-900;
    font-style: normal;
    src: url("fonts/Lato/lato-v15-latin-900.eot"),
          url("fonts/Lato/lato-v15-latin-900.woff") format("woff2"),
          url("fontsLato/lato-v15-latin-900.woff2") format("woff"), 
          url("fonts/Lato/lato-v15-latin-900.ttf") format("truetype"),
          url("fonts/Lato/lato-v15-latin-900.svg") format("svg");
    /* Legacy iOS */ }