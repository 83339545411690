#header-1 {

	position: relative;
	height: 910px;
	@include brgImg('../images/uploads/pagebanner/header1-banner.jpg');
}

.header-content1 {
	height: 110px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.header-1-nav {
		transition: 0.5s;
		ul {
			list-style-type: none;
	
		}
		li {
			display: inline-block;
			position: relative;
			&:hover > ul{
				display: block;
			}
	
			& > ul {
				background-color: $white;
				display: none;
				border-top: 3px solid $yellow;
				@include boxShadow(0px 4px 5px 2px rgba(0,0,0,0.35));
				margin: 0;
				position: absolute;
				li{
					padding: 5px 0;
					&>ul{
						top: 0;
						left: 100%;
					}
					width: 200px;
					border-bottom: 1px solid #eeeeee;
					&:last-child {
						border-bottom: 1px solid transparent;
					}
				}
				a{	
					background-color: transparent;
					color: $main-dark;
					padding: 7px;
					&:hover {
						color: $yellow !important;
						background-color: transparent;
					}
				}
	
			}
			a {
				font-family: $font-primary-bold;
				display: block;
				padding: 40px 16px 30px;
				color: #ffffff;
				border-radius: 0 0 5px 5px;
				&:hover {
					background-color: $yellow;
					color: $main-dark;
				}
			}
		}
	}

	&__hamburger {
		display: none;
		span {
		
			height: 4px;
			transition: all 0.5s;
			width: 30px;
			background-color: rgba($color: #ffffff, $alpha: 0.9);
			display: block;
			border-radius: 6px 6px 2px 2px;
			margin-bottom: 4px;
		}
		
	}

}

.header-tittle1 {
	text-align: center;
	margin-top: 205px;
}

#form-1-area {
	bottom: 30px;
	width: 100%;
    position: absolute;
}








