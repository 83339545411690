/// -------------------------------- SLIDER BANNER 1- RESPONSIVE-------------------------------:
.slider-banner{
    @media ( max-width: 1651px ) {
        &__item__text {
            h1 {
                font-size: 80px;
            }   
            p {
                width: 300px;
                margin-bottom: 10px;
            }
        }
    }
    @media ( max-width: 1200px ) {
        &__item__text {
            top: 20px;
        }
    }
    @media ( max-width: $md ) {
        &__item__text {
            h1 {
                font-size: 30px;
            }   
            p {
                width: 300px;
                margin-bottom: 10px;
            }
            a {
                height: 35px;
                line-height: 35px;
                font-size: 14px;
            }
        }
    }
    @media ( max-width: $sm ) {
        .slider-banner__item {
            & > img {
                min-height: 160px;
            }
        }
        &__item__text {
            h1 {
                font-size: 20px;
            }   
            p {
                width: 300px;
        
                font-size: 13px;
            }
            a {
                height: 25px;
                line-height: 25px;
                font-size: 13px;
            }
        }
    }

}
@media ( max-width: 1200px ) {
    .index-2-form-area {
        margin-top: -40px !important;
    }
}
@media ( max-width: 790px ) {
    .index-2-form-area {
        margin-top: 10px !important;
    }
}
@media ( max-width: 650px ) {
    .index-2-form-area {
        margin-top: 40px !important;
    }
}
/// --------------------------------END SLIDER BANNER 1- RESPONSIVE-------------------------------:




/// --------------------------------SLIDER BANNER 2- RESPONSIVE-------------------------------------:
@media (max-width:1024px) {
    .index-3-form-area {
        margin-top:-5px;
    }
    .slider-banner-2__item {
        &__text {
            top: 50%;
            h1{ font-size: 50px;}
        }
    }
}
@media(max-width:$sm){
    .index-3-form-area {
        margin-top:40px;
    }
}
@media (max-width:580px) {
    .slider-banner-2__item {
        &__text {
            h1 {
                font-size: 30px;
            }
            span {
                width: 50px;
                font-size: 18px;
            }
            a {
                height: 30px;
                width: 130px;
            }
        }
    }
}

/// --------------------------------SLIDER BANNER 3- RESPONSIVE-------------------------------------:
@media(max-width:1900px) {
    .index-4-form-area {
        margin-top: -170px;
        
    }
}
@media(min-width:1901px) {
    .index-4-form-area {
        margin-bottom: 200px;
    }
}
.slider-banner-3 {
    @media(max-width:$xl) {
        .slider-banner-3__item__text {
            h1 {
                font-size: 50px;
            }
        }
    }
    @media(max-width:$md) {
        .slider-banner-3__item__text {
            span {
                font-size: 20px;
            }
        }
    }
    @media(max-width:$sm) {
        &__destination {
            width: 100%;
        }
        .slider-banner-3__item__text {
            top: 10%;
            h1 {
                font-size: 25px;
            }
        }
    }
}


/// --------------------------------SLIDER BANNER 4- RESPONSIVE-------------------------------------:


@media (max-width:$xl) {
    .slider-banner-4 {
        span {
            font-size: 25px;
        }
        &__item__text {
            h1 {
                font-size: 50px;
            }
        }
    }
}

@media (max-width:$lg) {
    .slider-banner-4 {
        span {
            font-size: 20px;
        }
        &__item__text {
            h1 {
                font-size: 30px;
            }
        }
        .slider-banner-2__item__text__line{
            width: 100px !important;
        }
        &__item__btn {
            bottom:30px;
            left: 30px;
            &__img {
                width: 50px;
                height: 50px;
            }
            img {
                width: 34px;
            }
            &__text{
              font-size: 13px;  
              margin: 0;
            }
            
        }
    }


    .page-figure {
        &__figure {
            span {
                font-size: 50px;
            }
            p{
                font-size: 20px;
            }
        }
    }
}

@media (max-width:$sm) {
    .page-figure__figure {
        height: auto;
        span {
            font-size: 40px;
        }
    }
    .slider-banner-4 {
        &__item__text-area {
            top: 5%;
        }
        span {
            font-size: 15px;
        }
        &__item__text {
            h1 {
                margin: 0;
                font-size: 20px;
            }

        }
        .slider-banner-2__item__text__line{
            width: 80px !important;
            margin: 4px 20px !important;
        }

        &__item__btn {
            bottom:10px;
            left: 15px;
            &__img {
                width: 40px;
                height: 40px;
            }
            img {
                width: 30px;
            }
            &__text{
              font-size: 13px;  
              margin: 0;
            }
            
        }

    }
}