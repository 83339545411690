//---------------------------.subsriber-form responsive
@media ( max-width: 768px ){
    .subsriber-form--padding {
        padding: 0;
        .subsriber-form__submit {
            right: 15px;
        }
    }

}
@media ( max-width: $sm ){
	.subsriber-form > div {
		padding: 0;
	}
    .subsriber-form--padding {
        .subsriber-form__text-input {
			padding: 10px 15px !important;
			height: 40px;
		}
        .subsriber-form__submit {
			right: 0;
			width: 100px;
			height: 40px;
        }
    }

}
//---------------------------end subsriber-form responsive



//--------------------------- form-1 responsive
.form-area {
	@media (max-width: $lg){
		#wanderlust-form1 {
			max-width: 480px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
		}
		min-height: 320px;
	}

}

.form__item {
	@media (max-width: $xl){
		margin: 0 5px;
		text-align: left;

	}
	@media (max-width: $lg){

		margin-bottom:50px;
		&--submit{
			margin: 10px 0 0 0;
		}
	}
	
}
@media (max-width: $sm){
	.form-area {
		min-height: 500px;
		#wanderlust-form1 {
			padding: 0 15px;
			min-height: 500px;
		}
	}
	.form__item {
		& > div {
			width: 100%;
		}
		margin: 0 0 30px 0;
		width: 100%;
		&--submit{
			margin: 10px 0 0 0;
		}
		.custom-select {
			width: calc(100% + 2px);
		}
		.select-items {
			width: calc(100% + 2px);
			
		}
	}
}


//--------------------------- end form-1 responsive