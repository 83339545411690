.travel-review-2 {
    position: relative;
    padding: 65px 0;
    text-align: center;
    &__item {
        img {
            display: inline-block;
        }
        padding: 65px 30px 65px 60px;
        h3 {
            @include fontface($font-testi,25px,$main-dark,normal);
        }
        h4{
            text-transform: uppercase;
            margin-bottom: 20px;
            @include fontface($font-primary,25px,$main-dark,normal);
        }
        p {
            color: $dove-gray;
        }
    }

    &__video {
        
        height: 450px;
        iframe {
            width:100%;
            height:100%;
            background-color:#000000;
        }

    }
    &__timesheet {
        color: $dusty-gray;
        margin: 0px 0 20px;
        i {
            margin-right: 10px;
        }
        img {
            margin: 0 10px 0 30px;
        }
    }
    &__view-btn {
        transition: all 0.3s;
        text-transform: uppercase;
        color: $turquoise !important;
        img {
            margin-left: 8px;
        }
        &:hover {
            font-family: $font-primary-medium;
        }
    }


    &__blur-brg {
        position: absolute;
        width: 76%;
        height: 100%;
        top: 0px;
        left: 0;
        background-color: #f9f9f9;
    }
}

.travel-review-2-slider {
    margin-top: 110px;
}

.testi-arrow-2-right {  
    @include reviewArrowStyle2();
    right: -25px;
    
}
.testi-arrow-2-left {  
    @include reviewArrowStyle2();
    left: -25px;
 
}
@media(max-width:$sm) {
    .testi-arrow-2-left {
        left: 5px;
    }
    .testi-arrow-2-right {
        right: 5px;
    }
}