.erro-page {
    z-index: -1;
    height: 100%;
    width: 100%;
    position: absolute;
    @include brgImg('../images/404.jpg');
    .header-content2__logo__sitename {
        img {
            -webkit-filter:brightness(0) invert(1) !important;
            filter: brightness(0) invert(1) !important;
        }
    }
    .header-content2__hamburger {
        span {
            background-color: $white;
        }
    }
    .header-2-nav {
        ul {
            color: $white;
            .sub-menu {
                color: $main-dark;
            }
            a {
                color: inherit !important;
                &:hover {
                    color: $yellow !important;
                }
            }
        }
    }
    .header-content2__call {
        img {
            -webkit-filter:brightness(0) invert(1) !important;
            filter: brightness(0) invert(1) !important;
        }
        p {
            color: $white;
        }
    }

    &__mess {
        padding: 0 15px;
        position: absolute;
        width: 100%;
        top: 70%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-align: center;
        p{
            letter-spacing: 2px;
            font-family: $font-secondary-light;
            color: $white;
            font-size: 35px;
            @media(max-width:$md){
                font-size: 20px;
            }
        }
        
    }
    &__return-btn {
        width: 130px;
        height: 45px;
        background-color: $yellow;
        border-radius: 5px;
        display: inline-block;
        color: $main-dark;
        line-height: 45px;
        text-align: center;
        font-family: $font-primary-medium;
        &:hover {
            color: $white;
        }
    }




}