////--------------------- destination-1 reponsive:
@media ( max-width: 1550px ) {
    .top-desti{
        margin-top: -30px;
        flex-wrap: wrap;
        justify-content: center;
        &__item {
            margin-top: 30px;

        }
    }
}



////-----------------------  destination-2 reponsive:

// .popular-destination {
//     @media (min-width:1652px) {
//         height: 600px !important;
//         &__item:nth-child(3)  {
//             left: 715px !important;
//         }  
//         &__item:nth-child(5)  {
//             left: 936px !important;
//         } 
//         &__item:nth-child(6)  {
//             right:0 ;
//             left: unset !important;
//             top: 0 !important;
//         } 
//     }
// }


////-----------------------  destination-3 reponsive:

.destination-3 {
    @media (max-width:1024px) {
        &__figure-item {
            padding: 0 0 30px;
        }
        &__figure {
            padding: 30px 0 0;
        }

    }

    @media (max-width:$xl) {
        &__destination {
            flex-flow: wrap;
            justify-content: center;

        }
        &__thumb {
            width: 33.33333%;
  
        }
    }
    @media (max-width:767px) {
        &__destination {
            padding-top: 30px;
        }
        &__thumb {
            width: 50%;
            &:hover {
                margin-top: -60px;
             
                img {
                    @include boxShadow(0px 0px 5px 5px rgba(0,0,0,0.62));
                }      
                z-index: 2;
            }
        }
    }
    @media (max-width:474px) {
        
        &__thumb {

            width: 80%;
            &:hover {
                margin-top: 0;
            }
            margin-bottom: 30px;
        }
        &__thumb-info {
            margin-top: 5px;
            position: unset;
        }

    }

}

////-----------------------  destination-4 choose  reponsive:


@media (max-width:$xl) {
    .destination-4__form {
        padding:110px 0 0 0;
    }
    .destination-4__choices {
        &__info {
            margin-left: 0;
        }
        &__item {
            justify-content: space-around;
        }
    }
}
@media (max-width:$lg) {
    .border-img {
        width: 100%;
    }
    .destination-4-2__tittle {
        margin: 10px 0;

    }
    .destination-4-2__item {
        margin: 5px 0 60px;
    }
    .destination-4__choices {

        &__item {
            margin-bottom: 50px;
        }
    }
}
@media(max-width:$md){
    .destination-4__choices__item {
        margin-bottom: 50px;
    }
}
@media (max-width:$sm) {
    .destination-4__form {
        padding:60px 0 0 0;
    }
    .destination-form {
        padding: 40px 30px;
    }

}
@media (min-width:370px)and(max-width:575px) {
    .destination-4__choices__item {
        width: 60% ;
        margin-left: auto;
        margin-right: auto;
    }
}


@media (max-width:475px) {
    .destination-4-2__content {
        width: 75% ;
        display: inline-block;
    }
    .destination-4-2__arrow {
        top: -25px;
        &--left {
            right: 130px;
        }
        &--right {
            right: 70px;
        }
    }
    .destination-4-2__item {
        margin: 15px 0 60px;
    }

}




////-----------------------  destination-5 reponsive:
@media(max-width:$sm){
    .destination-5__item {
        width: 60%;
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        &__upper-tittle {
            order: 2;
            margin:20px 0 0;
        }
    }
    .destination-5 {
        margin-bottom: 30px;
    }
}
@media(max-width:475px){
    .destination-5__item {
        width: 70%;
        & > img {
            object-fit: cover;
        }
    }
}
@media(max-width:425px){
    .destination-5__item {
        width: 90%;
    }
}

////-----------------------end  destination-5 reponsive:




////-----------------------  .destination-item-aside   reponsive:


.destination-item-aside { 
    @media(max-width:$xl){
        .destination-item-aside__item--picture--mansory__item {
            height: 200px;
        }
    }
    @media(max-width:$md){
        .destination-item-aside__item--picture .row div {
            margin:0 auto 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    @media(max-width:$sm){
        .destination-item-aside__item--picture .row div div{
            width: 70%;
            text-align: center;
        }
    }
    
    @media(min-width:$md)and(max-width:$xl) {
        .destination-item-aside__relate-tour__item {
            .trending-tour-item {
                width: 90% !important;
                margin-left: auto;
                margin-right: auto;
            }
        }

    }

}





////-----------------------  .destination mansory   reponsive:


@media (min-width:1024px) {
    .destination-mansory-layout {
    
        display: grid;
        grid-template-columns: 22.82% 17.57% 31.2% auto;
        grid-auto-rows: 285px ;
        column-gap: 30px;
        row-gap: 30px;
        &__item { 
            img {
                width: 100%;
                height: 100%;
            }

            &--hor-2 {
                grid-column-start:2;
                width: 177.54%;
            }
    
            &--nor-2{
                grid-column-start: 3;
                justify-self: flex-end;
                width: 56.32%;
            }
            &--ver-2 {
                grid-row-start: span 2;
            }
            &--ver-1 {
                grid-column-start: 1;
                grid-row-start: span 2;
            }
        }
    }
}

@media(max-width:1651px){

    .destination-mansory-layout {
       
        grid-auto-rows: 200px ;
        column-gap: 20px;
        row-gap:20px;
    }
}
@media (min-width:$sm)and(max-width:1023px) {
    .destination-mansory-layout {
        display: grid;
        grid-template-columns: 35% auto 35%;
        grid-auto-rows: 180px;
        column-gap: 15px;
        row-gap:15px;
        &__item {
            img {
                width: 100%;
                height: 100%;
            }
            &--ver-1 {
                grid-column-start: 1;
                grid-row-start: span 2;
            }
            &--ver-2 {
                grid-column-start: 3;
                // height: 209%;
                grid-row-start: span 2;
            }
        }

    }


}

@media (max-width:$md ) {
    .destination-mansory-layout {
        
        grid-template-columns: auto auto;
        grid-auto-rows: 180px;
        justify-content: center;
      
        &__item {

            &:nth-child(6) {
                grid-row-start: 3;
            }
            &:nth-child(12) {
                grid-row-start: 7;
            }

            &--ver-2 {
                grid-column-start: 2;
                grid-row-start: span 2;
            }
            &--hor-1 {
                grid-column-start: 2;
            }
            &--nor-2 {
                grid-column-start: 1;  
            }
            
        }
    }
    .destination-mansory-layout__pagination {
        padding: 50px 0 50px;
    }

}
@media(max-width:$sm){
    .destination-mansory-layout
        {
            margin-top: 50px;
            &__item { 
                width: 80%;
                margin: 15px auto;
            }
        }
}
