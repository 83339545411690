.video-popup-2 {
    &__area{
        position: relative;
    }
    
    &__tittle {
        width: 100%;
        text-transform: uppercase;
        transform: translate(-50%, -50% );
        z-index: 1;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        h2 {
            margin-bottom: 25px;
            @include fontface($font-video-tittle,50px,#ffffff,normal);
        }
        &__btn-info {
            a {
                display: inline-block;
                width: 130px;
                height: 45px;
                background-color: $white;
                line-height: 45px;
                border-radius: 6px;
                margin: 0 11px;
                color: $main-dark;
                &:hover {
                    font-weight: 600;
                    color: $yellow;
                }
            }
            &--book {
                background-color: $yellow !important;
                &:hover {
                    font-weight: 600;
                    color: $white !important;
                }
            }
        }
    }
    #btn-popup2 {
        cursor: pointer;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.5s;
        margin-bottom: 35px;

        &:hover{
            background-color: rgba($color: #ffffff, $alpha: 0.25);
        }
    }

}
@include PreViewVideo(preview-video-2);
#video-popup-2 {
    position: relative;
    .worldBrg {
        z-index: -1;
        bottom: -375px;
        left: 0;
        width: 70%;
    }
}