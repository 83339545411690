.our-team {
    padding-top: 73px ;
    .travel-review__sub-tittle {
        margin-bottom: 60px;
    }

    .our-member {
 
        &:hover {
            .our-member__info  {
                margin-top: -120px;
            }
        }
        overflow: hidden;
        position: relative;
        &__thumb {
            width: 100%;
            vertical-align: middle;
        }

        &__info {
            transition: all 0.5s;
            position: absolute;
            height: 120px;
            background-color: $white;
            width: 100%;
            padding: 15px 0 10px;
            text-align: center;
            h5 {
                font-family: $font-primary-medium;
                margin: 0;
            }
            span {
                color: $dove-gray;
                text-transform: uppercase;
            }
            &__social {
                img {
                    margin: 12px;
                }
            }
        }
    
    }   
}

