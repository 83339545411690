.blog-comment {
    h5 {
        font-family: $font-primary-medium;
        font-size: 20px;
        margin-bottom: 30px;
    }
    .blog-comment-box {
        padding-bottom: 20px;
    }
    .blog-comment-box__item {
        &--reply {
            margin: 20px 0 20px 100px;
        }

        &__avatar {
            float: left;
            width: 70px;
            margin-right: 30px;
            img {
                width: 70px;
                height: 70px;
            }
            
        }
        &__comment {
            margin-left:100px; 
            span {
                font-family: $font-primary-medium;
                &:nth-child(1){
                    text-transform: uppercase;
                    margin-right: 25px;
                }
                &:nth-child(2){
                    color: $dusty-gray;
                    font-size: 13px;
                }
            }
            p {
                color: $dove-gray;
                margin: 7px 0 10px;
            }
        }

        a {
            color: $yellow;
            i {
                margin-right: 5px;
            }
        }
    }
}



