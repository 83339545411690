@include sectionTittleMiddle (travel-tip-2);
.travel-tip-2__item {
    display: flex !important;

    & > div {
        padding-right: 0;
    }
    position: relative;
    height: 500px;
    align-items: center;
    flex-direction: row-reverse;
    
    .travel-tip-2__thumb {
        position: absolute;
        left: 0;
    }

    &__info {
        display: block;
        padding: 80px 70px 90px 100px;
        text-align: right;
        border: 8px solid $yellow;

        h3 {
            @include fontface($font-primary-bold,23px,$main-dark,normal);
            margin-bottom: 10px;
        }
        span {
            color: #bebebe;
            margin-left: 20px;
            i {margin-right: 5px;}
        }
        p {
            color: $dove-gray;
            margin: 20px 0 0 0;
        }
    }
    

} 
.travel-tip-2 {
    padding-bottom: 110px;
    .slick-dots {
        top: unset;
        bottom: 18% ;
        right: 70px;
        li {
            margin: 0;
        }
        .slick-active {
            button{
                &::before {
                   width: 12px !important; 
                }
                
            } 
        }  
    }
}


