.contact-infomation {
    padding: 65px 0 90px;
    &__item {
        margin-bottom: 50px;
    }
    h5 {
        font-size: 30px;
        margin-bottom: 25px;
    }
    &__form {
        label {
            margin: 20px 0 5px;
        }
        input[type='submit']{
            margin-top: 25px;
        }
        textarea {
            height: 200px;
        }
    }

    &__info {
        p {
            color: $dove-gray;
        }
        &__address {
            border-bottom: 1px solid #e6e6e6;
        }
        &__address-item {
            img {
                margin: 0 15px;
            }
            border-top: 1px solid #e6e6e6;
            height: 45px;
            display: flex;
            align-items: center;
        }
    }

    &__working-time {
        margin-top: 45px;
        p {
            font-size: 18px;
            color: $turquoise;
            margin: 0;
        }
    }

    &__map {
        margin-top: 40px;
        position: relative;
        height: 550px;
        iframe {
            width: 100%;
            height: 100%;
        }
        .fa-map-marker-alt {
            position: absolute;
            font-size: 40px;
            color: $yellow;
            top: 60%;
            right: 30%;
            transform: translate(-50%,-50%);
        }
    }

}