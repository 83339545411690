.destination-4__form {
    padding:110px 85px 0 0;
}
.destination-form {
    padding: 75px 70px;
    position: relative;
    .border-img {
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        position:absolute;
        z-index: -1;
    }
    .subsriber-form-tittle {
        h2, span {
           color: $main-dark ;
        }
        &__sub-tittle {
            font-size: 13px;
            color: $dusty-gray;
        } 
        .subsriber-form-tittle__line-under {
            height: 15px;
        }
    }
    &__text {
        border: none !important;
        border-bottom: 1px solid #eeeeee !important;
        line-height: 0px;
        margin-bottom: 20px;
    }
    &__submit {
        cursor: pointer ;
        height: 45px !important;
        width: 125px !important;
        background-color: $yellow !important;
        text-align: center;
        margin-top: 20px;
        border-radius: 5px;
        border: unset;
    }
}

.destination-4__choices {
    margin-bottom: 25px;
    &__tittle {
        margin-bottom: 0 !important;
    }
    @include sectionTittle(destination-4__choices);

    &__thumb {
        overflow: hidden;
        border-radius: 5px;
        img {
            transition: all 0.5s;
            vertical-align: middle;
        }
    }
    &__item {

        &:hover {
            .destination-4__choices__thumb >img {
                transform: scale(1.1);
            }
            .destination-4__choices__info {
                span{
                    color: $yellow;
                }
            }
        }
        overflow: hidden;
        color: $main-dark !important;
        margin-bottom: 64px;
        display: flex;
        align-items: center;
      
        span {
            transition: all 0.3s;
            text-transform: uppercase;
            font-weight: bold;
       
        }
        p {
            margin: 0;
            color: $dove-gray;
            font-size: 14px;
        }
    }
    &__info {
        margin-left: 30px;
    }
}