// ---------------------------------------- video-popup-1 reponsive

@media (max-width:$lg){
    .video-popup-1__head-Brg {
        height: 350px;
    }
    #btn-popup {
        img {
            width: 65px;
            height: 65px;
        }
    }
}
@media (max-width:$md){
    #btn-popup {
        img {
            width: 55px;
            height: 55px;
        }
    }
}
@media (max-width:$sm) {
    #btn-popup {
        img {
            width: 45px;
            height: 45px;
        }
    }
    .video-popup-1 {
        margin-top: 15px;
        min-height: 350px;
        .video-popup-1__tittle > img {
            margin: 30px 0 15px;
        }
        h2 {
            
            font-size: 30px;
        }
    }
    .video-popup-1__area {
        margin-top: 0;
    }
}




// ---------------------------------------- video-popup-2 reponsive

.video-popup-2 {
    @media (max-width:768px) {
        padding: 0 15px;
    }
}
.video-popup-2__tittle {
    @media (max-width:1024px) {
        #btn-popup2, p {
            margin: 0;
        }
        h2 {
            font-size: 30px;
        }
    }
    @media (max-width:768px) {
        #btn-popup2 {
            img {
                width: 44px;
                height: 44px;
            }
            width: 60px;
            height: 60px;
        }
 
        .video-popup-2__tittle__btn-info {
            a {
                height: 30px !important;
                line-height: 30px !important;
            }
        }
    }
    @media (max-width:$sm) {

        h2 {
            font-size: 20px;
        }
        .video-popup-2__tittle__btn-info {
            a {
                font-size: 10px;
                width: 100px;
            }
        }
    }
}
.video-popup-2__area {
    @media (max-width:$sm) {
        & > img {
            min-height: 200px;
        }
    }
}




// ---------------------------------------- preview video-about ius reponsive


@media(max-width:$xl) {
    .preview-video-aboutus__text-behind {
        font-size: 150px ;
        margin-bottom: -60px;
    }
}
@media(max-width:$md) {
    .preview-video-aboutus__text-behind {
        font-size: 100px ;
        margin-bottom: -40px;
    }
}