.destination-grid-layout {
    padding: 65px 0 100px;
    .top-desti__item {
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        margin: 0 0 30px;
        display: flex;
        &::after {
            bottom: 0;
            margin-bottom: -120px;
        }
        span {
            &:first-child{
                text-transform: uppercase;
            }

        }
        img {
            height: 250px;
            transition: all 0.5s;
            width: 100%;
  
        }
        &:hover {
            &::after {
                margin-bottom: 0;
            }
            img {
                transform: scale(1.1);

            }
        }
    }

    .wander-pagination__pagination {
        margin-top: 20px;
    }
}
