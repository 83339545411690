.related-blog {
    padding: 45px 0 40px 0;
    h5 {
        margin-bottom: 30px;
        font-family: $font-primary-medium;
    }
    &__item {
        display: block;
        padding: 0 15px;
        &__thumb {
            height: 270px;
            img {
                transition: all 0.5s;
                width: 100%;
                height: 100%;
            }
            overflow: hidden;
            margin-bottom: 20px;
        }
        &:hover {
            img {
                transform: scale(1.2);
            }
        }
        p {
            font-family: $font-primary-medium;
            margin: 0;
            color: $main-dark;
        }
        span {
            color: $dusty-gray;
        }
    }
}