.destination-item-aside {
    padding-top: 60px;
    &__item {
        display: block;
        &:first-child {
            margin: 0;
        }
        margin-top: 40px;
        p {
            margin-top: 10px ;
            color: $dove-gray;
            margin-bottom: 0px;
        }

        &--picture {
            padding: 45px 0 35px;
            img {
                vertical-align: middle;
            }

        }

        &--picture--mansory {

            &__item {
                border-radius: 4px;
                overflow: hidden;
                height: 260px;
                margin-bottom: 30px;
                img {
                    height: 100%;
                    width: 100%;
                }
                &--height2 {
                    height: 270px;
                }
            }
        }

    }

    @include sectionTittle(destination-item-aside__relate-tour);
}

