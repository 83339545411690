.video-popup-3__area {
    position: relative;
    .modal-box {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -2;
    }
    iframe {
        width: 100%;
        height: 100%;
        background-color: $main-dark;
    }
    .btn-popup3 {
        display: block !important;
    }
    .modal-close {
        position: absolute;
        color: $dusty-gray;
        top: -40px;
        right: 0;
        font-size: 30px;
    }
}