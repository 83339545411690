hr {
  height: 1px;
  margin-bottom: 1.5rem;
  border: 0;
  background-color: $dove-gray;
}

img{
	max-width: 100%;
}

.content{
	padding: 0 95px;
}

@media ( max-width: 1200px ){
	padding: 0 45px;
}

@media ( max-width: 991px ) {
	padding: 0 15px;
}
