///form-1
.form-area {
	display: flex;
    width: 100%;
	justify-content: center;
	align-items: center;	
    height: 170px;
    background-color: #ffffff;
	border-radius: 4px;

}

.form__item {
	margin-right: 30px;
	display: inline-block;
	height: 45px;
  color: $dove-gray;


	&--datepicker {
		width: 173px;
		position: relative;
    height: 100%;
    z-index: 1;
    &:hover > span {
        color: $turquoise;
    }
		input {  
      transition: unset;
      background-color: transparent;
      width: 100%;
      cursor: pointer;
			color: $dove-gray;
			padding-left: 20px;
			padding-right: 30px;
			height: 100%;
			letter-spacing: 1px;
      border: 1px solid #d9d9d9;
      &:focus {
        border: 1px solid $turquoise;
        color: $turquoise;
      }
      &:hover {
        border: 1px solid $turquoise;
        color: $turquoise;
      }

    }
	}
	span {
    z-index: -1;
		right: 20px;
		font-size: 15px;
    position: absolute;
		top: 50%;
    transform: translateY(-50%);
	}

	&--submit {
    margin: 0;
    width: 120px;
    input {
      width: 100%;
      height: 100%;
      cursor: pointer;
      background-color: $yellow;
      border: none;
      border-radius: 5px;
      color: #000000;
      padding: 0 20px;
      
    }


	}
}


///--------------------------------- custom slector
.custom-select {
  // transition: all .5s;
  position: relative;
  width: 180px;
  color: $dove-gray;
  height: 100%;
  border-radius: 5px;
  border: 1px solid #d9d9d9;

  &:hover {
    border-color: $turquoise;
    color: $turquoise !important;
    z-index: 2;
    .select-items li {
        border-color: $turquoise;
        color: $turquoise !important;
        
    }
  }

}
.solid-border {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom: 1px solid transparent !important;
}
.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.colorChange {
  color: $turquoise;

}
/*style the arrow inside the select element:*/
.select-selected{
  padding-left: 20px;
  &:after {
    position: absolute;
    content: "\f107";
    font-family: $awe;
    // transition: all .5s;
    right: 18px;
  }  

}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  transform: rotate(180deg);

}

/*style the items (options), including the selected item:*/
.select-items li,.select-selected {
  cursor: pointer;
  user-select: none;
  line-height: 45px;
  height: 100%;

}

.select-items li {
  list-style-type: none;
  z-index: 5;
  width: 100%;
  padding-left: 20px;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  background-color: #ffffff;
  &:last-child{
    border-bottom: 1px solid #d9d9d9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

/*style items (options):*/
.select-items {
  height: auto !important;
  position: absolute;
  width: 180px;
  z-index: 1;
  background-color: $white;
  left: -1px;
  // transition: all 0.5s;
  li {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(63, 206, 211, 0.295);
      left: 0;
      opacity: 0;
    }
  }
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;

}

.select-items li:hover, .same-as-selected {
  &::before{
    opacity: 1;
  }

  
}
.is-popup {
  z-index: 5;
}