
@include sectionTittle(trending-tour);
.trending-tour-item {
    margin-bottom: 50px;
    border-radius: 5px;
    @include boxShadow( -1px 10px 34px -1px rgba(0,0,0,0.12));
    width: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    img{
        vertical-align: middle;
        transition:all 0.5s;

    }
    &:hover > img{
        
        transform: scale(1.1);
        
    }

    &__sale {
        z-index: 1;
        top: 0;
        right: 0;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 53px solid red;
        border-left: 53px solid transparent;
        &::before{
            content: "SALE";
            position: absolute;
            bottom: 27px;
            right: 1px;
            transform: rotate(45deg);
            @include fontface($font-primary-bold,12px,$white,normal);  
        }
    }
    &__thumnail {
        width: 100%;
    }
    &__info {
        padding: 25px 20px;
        position: relative;
        background-color: #ffffff;
    }
    &__name {
        margin-bottom: 3px;
        text-transform: uppercase;
        @include fontface($font-primary-bold,17px,$main-dark,normal);
    }

    &__group-infor {
        
        ///info--left
        &__rating {
            &::before {
                content: "\f005  \f005  \f005  \f005  \f005";
                font-family: $awe;
                font-size: 13px;
                font-weight: 400 !important;
                color: $turquoise;
            }
            &--4star {
                &::before{
                    @extend .trending-tour-item__group-infor__rating;
                    content: "\f005  \f005  \f005  \f005  \f006";

                }

            }
            &--3star {
                &::before {
                    @extend .trending-tour-item__group-infor__rating;
                    content: "\f005  \f005  \f005  \f006  \f006";
                }
            }
            &--2star {
                &::before {
                    @extend .trending-tour-item__group-infor__rating;
                    content: "\f005  \f005  \f006  \f006  \f006";
                }
            }
            &--1star {
                &::before {
                    @extend .trending-tour-item__group-infor__rating;
                    content: "\f005  \f006  \f006  \f006  \f006";
                }
            }
        }

        &__lasting {
            margin-top: 18px;
            color:$dove-gray;
            img {
                margin: 0 5px -3px 0;
            }
        }
        ///end-infor---left

        /// infor---right

        &__sale-price {
            margin: 0;
            position: absolute;
            right: 20px;
            top: 52px;
            text-decoration: line-through;
            @include fontface($font-primary-medium,20px,$dusty-gray,normal);
        }
        &__price {
            position: absolute;
            top: 75px;
            right: 20px;
            @include fontface($font-primary-bold,27px,$yellow,normal);
        }
         ///end-infor---right
    }
}

