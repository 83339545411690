.type-of-tour-area {
    @include brgImg('../images/uploads/destination/icon5-brg.jpg');
    height: 550px;
    display: flex;
    align-items: center;
}
@include sectionTittleMiddle (type-of-tour);
.type-of-tour__tittle {
    margin: 0;
    h2 {
        color: rgba($color: $white, $alpha:0.2);
    }
    p,span {
        color: $white ;
    }
}
.type-of-tour {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    &__item {
        display: block;
        margin-top: 65px;
        text-align: center;
        p {
            margin: 35px 0 0;
            @include fontface($font-primary-medium,22px,$white,normal);
        }
        &__img {
            height: 85px;
        }
    }
}



@media (max-width:$md) {
    .type-of-tour {
        margin-top: 40px;
        justify-content: space-around;
        &__item {

            margin: 15px;
            p {
                margin: 0px;
            }
        }
    }

}