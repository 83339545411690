.tour-item-banner-2 {
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    h4 {
        text-transform: uppercase;
        margin: 0;
        @include fontface($font-primary,15px,$dove-gray,normal);
    }
    span {
        color: $turquoise;
    }
}