.grid-left-sidebar {
    @extend .col-4-tour;
    padding: 65px 0 100px;

    &__filter {
        margin-bottom: 50px;
        height: 38px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--result {
            @include fontface($font-primary-bold,17px,$main-dark,normal);
        }

        &--mode {
            display: inline-flex;
            height: 38px;
            .custom-select {
                width: 220px;
            }
            .option-item ,.option-item  li {
                line-height: 38px;
            }
   
            &--sort {
                transition: all .5s;
                margin-left: 13px;
                text-align: center;
                line-height: 38px;
                height: 100%;
                width: 38px;
                border-radius: 4px;
                border: 1px solid #d9d9d9;
                cursor: pointer;
                &:hover {
                    background-color: $turquoise;
                    img {
                        -webkit-filter: brightness(0) invert(1);
                        filter: brightness(0) invert(1);
                    }
                }
            }
            
        }
    }

}


