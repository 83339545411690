
.header-nav-mobile {
    @include boxShadow(-1px 19px 34px -13px rgba(0, 0, 0, 0.3)); 
	display: none ;
	position: absolute;
	z-index: 5;
	width: 100%;
	background-color:$white;
	top: 110px;
	ul {
		border-top:3px solid $turquoise;
		margin: 0;
		padding: 0 15px;
		li {
			display: block;
			border-bottom: 1px solid #e6e6e6;
			&:last-child{
				border-bottom: unset;
			}
			& > ul {
				border-top: unset;
				display: none;
			}
		}
		a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			font-family: $font-primary-medium;
			color: $main-dark;
			padding: 10px 0;
			font-size: 14px;
		}
    }
    
    &__search-area {
        display: flex;
        align-items: center;
        height: 40px;
    }
    &__signin {
        width: 40px;
    }
    &__search-bar {
        width: 100%;
        input {
            margin: 0 15px;
            padding: 0;
            height: 30px;
            border: none;
            border-bottom: 3px solid $dusty-gray;
            width: 0;
        }
  
    }
}
.ham-open {
	span {
		position: absolute;
		right: 0;
		border-radius: unset;
		height: 3px;

		&:nth-child(2){
			width: 0;
		}
		&:nth-child(1){
			@include Rotate(45deg);
			
		}
		&:nth-child(3){
			@include Rotate(-45deg);
		}
	}
}


// -----------Toogle Class
// .header-search-mobile-show {
//     input {
//         width: 100%;
//         padding: 0 15px;
//     }

// }
.sub-menu-open {
	& + ul {
		display: block !important;
	}
	& > i {
		@include Rotate(180deg);
	}
}
