/*--------------------------------------------------------------
BASE WEB
--------------------------------------------------------------*/
@import "base/base";
/*--------------------------------------------------------------
GENERAL
--------------------------------------------------------------*/
@import "templates/general";

/*--------------------------------------------------------------
HEADER
--------------------------------------------------------------*/
@import "templates/header/header";

/*--------------------------------------------------------------
FOOTER
--------------------------------------------------------------*/
@import "templates/footer/footer";
/*--------------------------------------------------------------
REPONSIVE
--------------------------------------------------------------*/
@import "responsive/reponsive";








