label {
  display: block;
  margin-bottom: 15px;
  color: $main-dark;
  @include font-size(15px);
}

fieldset {
  margin-bottom: 1rem;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
  display: block;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  background: $white;
  color: $color-primary;
  @include border-radius(3px);
  box-shadow: none;
  transition: all 0.3s ease-out;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
  border: 1px solid $color-primary;
  outline: 0;
}
input[type="submit"]{
  transition: all 0.3s;
  &:hover {
    font-family: $font-primary-medium;
    color: $white;
  
  }
}

textarea {
  overflow: auto;
}

/* Placeholder text color -- selectors need to be separate to work. */

::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-input-placeholder {
  color: $dusty-gray;
  font-family: $font-primary;
}
