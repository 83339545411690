//------------------------------ GALLERY-1 ------------
@media (min-width:$lg)and(max-width:$xl){
    .gallery {
        .slick-dots {
            right: 45% ;
        }
    }
    .gallery-control {
        margin-top: -45px;
        height: 100px;
        padding: 0 30px;

    }
    .gallery-control__arrow {
        margin-left: 30px;
    }
}
@media (min-width:$lg){
    .gallery {
        padding-top: 60px;
    }

}
@media (max-width:$lg) {
    .gallery-control {
        margin-top:-45px;
        height: 100px;
        padding: 15px 30px 10px;
        flex-direction: column;
        h2 {
            display: none;
        }
        span {
            font-size: 15px;
        }
    }
}

@media (max-width:$sm) {
    .gallery-control {
        margin-top: -35px;
        height: 90px;
    }
    .gallery {
        .slick-dots {
            width: 80%;
        }
    }
}




//------------------------------ GALLERY-SYNCING

@media(max-width:$xl) {
    .gallery__syncing {
        &__area {
            width: 80%;
        }
    }
}
@media(max-width:$lg) {
    .gallery__syncing {
        &__area {
            width: 90%;
        }
    }

}
@media(max-width:$md) {
    .gallery__syncing__btn-close {
        top: -40px;
        right: -10px;
    }
    .gallery__syncing__single  {
        .slick-arrow {
            font-size: 30px;
        }
        .fa-angle-left {
            left: -15px;
        }
        .fa-angle-right {
            right: -15px;
        }
    }
}
