#footer-1 {
    padding-top: 100px;
    @include brgImg('../images/uploads/pagebanner/footerbanner.jpg');
    height: 900px;
    width: 100%;
    position: relative;
}

.footer-widget-1 {
    margin-top: 230px;

    &__tittle {
        height: 50px;
        
        h5 {
            line-height: 50px;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 18px;
            margin: 0;
            letter-spacing: 2px;
        }
        &__line-under {
            width: 50px;
            margin-top: -10px;
            border-top: 3px solid $turquoise;
        }
    }

    &__lists {
        text-align: justify;
        &::after {
            content: "";
            display: inline-block;
            width: 100%;
        }
    }
    &__list {
        display: inline-block;
        ul {
            list-style-type: none;
            margin-top: 45px;
            li {
                margin-bottom: 13px;
                color: $dusty-gray !important;
            }
            a {
                &:hover {
                   color: $yellow; 
                }
                color: $dusty-gray;
            }
        }
    }

    ///--------under logo
    &__text {
        p {
            margin-top: 40px;
            line-height: 27px;
        }
        color: $dusty-gray;

    }
}

.copyright {
    position: absolute;
    width: 100%;
    bottom: 0;

    &__area {
        height: 64px;
        line-height: 64px;
        border-top: 1px solid #424547;
        font-size: 13px;
        color: $dove-gray;
        display: flex;
        justify-content: space-between;
    }
    &__social {
        img {
            display: inline-block;
            margin-left: 23px;
        }
    }
}
