/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 Normalize
2.0.Fonts
3.0 Variables
4.0 Mixins
5.0 Boostrap Grid
6.0 Accessibility
7.0 Alignments
8.0 Typography
9.0 Forms
10.0. Tables
11.0 Formatting
12.0 Lists
13.0 Links
14.0 Navigation
15.0 Comments
16.0 Widgets
17.0 Modules

--------------------------------------------------------------*/

/*--------------------------------------------------------------
1.0 Normalize
Styles based on Normalize v8.0.0 @link https://github.com/necolas/normalize.css
--------------------------------------------------------------*/

@import "base/normalize";

/*--------------------------------------------------------------
2.0 Fonts
--------------------------------------------------------------*/
@import "fonts/awesome";
@import "fonts/fontawsome5regular/fontawesome";
@import "fonts/lato";
@import "fonts/ubuntu";
@import "fonts/racingsanone";
@import "fonts/poppins";
@import "fonts/birdrocker";



/*--------------------------------------------------------------
3.0 Variables
--------------------------------------------------------------*/

@import "base/variables";

/*--------------------------------------------------------------
4.0 Mixins
--------------------------------------------------------------*/

@import "base/mixins";

/*--------------------------------------------------------------
5.0 Bootstrap Grid
--------------------------------------------------------------*/

@import "base/bootstrap-grid";

/*--------------------------------------------------------------
6.0 Accessibility
--------------------------------------------------------------*/

@import "base/accessibility";

/*--------------------------------------------------------------
7.0 Alignments
--------------------------------------------------------------*/

@import "base/alignments";

/*--------------------------------------------------------------
8.0 Typography
--------------------------------------------------------------*/

@import "base/typography";

/*--------------------------------------------------------------
9.0 Form
--------------------------------------------------------------*/

@import "base/forms";

/*--------------------------------------------------------------
10.0 Tables
--------------------------------------------------------------*/

@import "base/tables";

/*--------------------------------------------------------------
11.0 Formatting
--------------------------------------------------------------*/

@import "base/formatting";

/*--------------------------------------------------------------
12.0 Lists
--------------------------------------------------------------*/

@import "base/lists";

/*--------------------------------------------------------------
13.0 Links
--------------------------------------------------------------*/

@import "base/links";

/*--------------------------------------------------------------
14.0 Navigation
--------------------------------------------------------------*/

@import "base/navigation";

/*--------------------------------------------------------------
15.0 Comments
--------------------------------------------------------------*/

@import "base/comments";

/*--------------------------------------------------------------
16.0 Widgets
--------------------------------------------------------------*/

@import "base/widgets";

/*--------------------------------------------------------------
17.0 Utilities
--------------------------------------------------------------*/

@import "base/utilities";