.left-sidebar {
    width: 100%;
    &__item {
        margin-bottom: 30px;
    }
    h3 {
        text-transform: uppercase;
        background-color: $turquoise;
        display: block;
        height: 50px;
        width: 100%;
        margin: 0;
        text-align: center;
        line-height: 50px;
        @include fontface($font-primary-medium,17px,$white,normal);
    }
    padding-right: 30px;

    .left-sidebar-form{
        padding: 0 27px 20px;
        background-color: #f7f7f7;

        &__submit {
            width: 100%;
            height: 40px;
            border: none;
            background-color: #ffc600;
            color: black;
            margin-top: 50px;
            border-radius: 4px;
        }
    }

    label {
        padding: 25px 0 7px ;
        margin: 0  ;
        font-family: $font-primary-medium;
    }
    .form__item {
        height: 40px;
        width: 100%;
        &--datepicker  {
            width: 100%;
            input {
                background-color: #f7f7f7;
                width: 100%;
            }
        }
    }

    .select-items li,.select-selected {
        line-height: 40px;
    }
    .custom-select {
        width: 100%;
    }
    .select-items {
        width: calc(100% + 2px);
    }

    &__price-range {

        &__value {
            display: flex;
            justify-content: space-around;
            margin-bottom: 10px;
            span {
                font-size: 11px;
                height: 18px;
                width: 35px;
                text-align: center;
                border-radius: 4px;
                display: inline-block;
                background-color: $turquoise;
                color: $white;
            }
        }

        .noUi-target {
        position: relative;
        direction: ltr;
        } 
        .noUi-base {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        /* Fix 401 */
        }
        
        .noUi-origin {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        }
        
        .noUi-handle {
        position: relative;
        z-index: 1;
        }
        
        .noUi-stacking .noUi-handle {
        z-index: 10;
        }
        
        .noUi-state-tap .noUi-origin {
        -webkit-transition: left 0.3s,top .3s;
        transition: left 0.3s,top .3s;
        }
        
        .noUi-state-drag * {
        cursor: inherit !important;
        }
        

        .noUi-horizontal {
        height: 5px;
        border-radius: 5px;
        }
        
        .noUi-horizontal .noUi-handle {
            cursor: pointer;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            left: -7px;
            top: 50%;
            transform: translate(0,-50%);
            background-color:$turquoise;
        }
        
        /* Styling;
        */
        .noUi-background {
        background: #e6e6e6;
        }
        
        .noUi-connect {
        background: $turquoise;
        }
        
        .noUi-origin {
        border-radius: 5px;
        }
        
        .noUi-target {
        border-radius: 5px;
        }
        

        
        /* Handles and cursors;
        */
        .noUi-draggable {
        cursor: w-resize;
        }
        
        .noUi-vertical .noUi-draggable {
        cursor: n-resize;
        }
        
        .noUi-handle {
        cursor: default;
        -webkit-box-sizing: content-box !important;
        -moz-box-sizing: content-box !important;
        box-sizing: content-box !important;
        }
        
        

    }




    // fliter type 

    .star-rating {
        &__field {
            position: relative;
            display: block;
            margin: 0 0 10px 0;
            height: 17px;
            width: 100px;
            cursor: pointer;
            
            &:hover {
                .star-rating__box {
                    border: 1px solid $turquoise;
         
                }
                input {
                    z-index: 2;
                }
                .choices-content {
                    color: $turquoise;
                }
            }
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 20px;
            width: 100px;
            &:checked ~ .star-rating__box{
                &::after {
                    visibility: visible;
                }
                border: 1px solid $turquoise;
            }
            &:checked ~ .choices-content {
                color: $turquoise;
            }
        }
        &__box {
            transition: all 0.5s ease;
            width: 17px;
            height: 17px;
            display: inline-block;
            border: 1px solid #cccccc;
            border-radius: 3px;

            &::after {
                content: "\f00c";
                font-family: 'Font Awesome 5 Free Solid';
                position: absolute;
                top: 0px;
                left: 0px;
                width: 17px;
                text-align: center;
                color: $turquoise;
                font-size: 12px;
                visibility: hidden;
            }
        }
        &__5-stars {

            &::after {
                top: 1px;
                margin-left: 10px;
                height: 17px;
                position: absolute;
                content: "\f005  \f005  \f005  \f005  \f005";
                font-family: $awe;
                font-size: 12px;
                font-weight: 400 !important;
                color: $turquoise;
            }
        }
        &__4-stars {
            @extend .star-rating__5-stars;
            &::after {
                content: "\f005  \f005  \f005  \f005  \f006";
            }
        }
        &__3-stars {
            @extend .star-rating__5-stars;
            &::after {
                content: "\f005  \f005  \f005  \f006  \f006";
            }
        }
        &__2-stars {
            @extend .star-rating__5-stars;
            &::after {
                content: "\f005  \f005  \f006  \f006  \f006";
            }
        }
        &__1-stars {
            @extend .star-rating__5-stars;
            &::after {
                content: "\f005  \f006  \f006  \f006  \f006";
            }
        }
    }
    .duration {
        @extend .star-rating;
        .choices-content {
            transition: all 0.5s;
            font-family: $font-primary-medium;
            color: $dove-gray;
            font-size: 13px;
            position: absolute;
            width: 100%;
            margin-left: 10px;
        }
        &__arrow-down {
            margin-bottom: -10px;
            margin-top: 15px;
            color: $turquoise;
            &::after {
                content: "\f107";
                font-family: "FontAwesome";
                margin-left: 10px;
            }
        }

    }
    .activity-type {
        @extend .duration;
        .duration__arrow-down {margin-bottom: 5px;}
    }
}






