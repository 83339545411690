@include sectionTittleMiddle(travel-review-3);

.travel-review-3 {
    &__expert {
        margin-bottom: 110px;
        text-align: center;
      
    }
    
}
.travel-review-3__expert-thumb {
    position: relative;
    img {
        vertical-align: middle;
        transition: all 0.5s;
    }

    overflow: hidden;
    &:hover {
        img {
            filter: brightness(40%);
            -webkit-filter: brightness(40%);
        }
        .travel-review-3__expert-thumb__info {
            margin-top:-120px; 
        }
    }
}
.travel-review-3__expert-thumb__info {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    &__name {
      
        color: $white !important;
        h5 {
            font-family: $font-primary-medium;
            margin: 0;
        }
        span {
            text-transform: uppercase;
        }
    }
    &__social {
        margin-top: 10px;
        img {
            -webkit-filter:brightness(0) invert(1) !important;
            filter: brightness(0) invert(1) !important;
            margin: 0px 12px;
        }
    }
}
    