.blog-grid-layout {
    padding: 65px 0 100px;
    &__item {
        display: block;
        margin-bottom: 45px;
        img {
            height: 100%;
            width: 100%;
            transition: all 0.5s;
        }
        &:hover {
            img {
    
                transform: scale(1.1);
            }
        }
        &__thumb{
            height: 250px;
            overflow: hidden;
            border-radius: 5px;
        }
        &__info {
            &__time {
                margin: 13px 0;
            }
        }
        h3 {
            @include fontface($font-primary-medium,20px, $main-dark,normal);
            margin-bottom: 10px;
        }
        span {
            font-size: 13px;
            color: $dusty-gray;
            margin-right: 20px;
            i {
                margin-right: 3px;
            }
        }
        p {
            color: $dove-gray;
            margin: 0;
        }
    }


    &__load-more {
        margin-top: 10px;
        text-align: center;
        a {
            font-family: $font-primary-medium;
            line-height: 45px;
            text-transform: uppercase;
            color: $main-dark;
            display: inline-block;
            background-color: $yellow;
            border-radius: 5px;
            width: 130px;
            height: 45px;
        }
    }
}