@include sectionTittle(travel-tip-1);
.travel-tip-1 {
    position: relative;
    margin-bottom: 110px;
    padding-bottom: 50px;
    &__btn {
        &:hover {
            &::before {
                color: $white;
                background-color: $turquoise;
            }
        }
        &::before {
            transition: all 0.5s ease;
            border-radius: 50%;
            left: 0;
            top: 0;
            position: absolute;
            content: "x";
            font-family: $font-primary;
            line-height: 30px;
            font-size: 25px;
            color: transparent;
            background-color: transparent;
            width: 100%;
            height: 100%;
        }

        cursor: pointer;
        position: absolute;
        z-index: 1;
        width: 35px;
        height: 35px;
        display: block;
        background-color: $yellow;
        text-align: center;
        line-height: 35px;
        border-radius: 50%;
        @include fontface($font-primary-medium,17px,$white,normal);

        &--location1 {
            bottom: 33%;
            left: 23%;
        }
        &--location2 {
            top: 23%;
            left:32%;
        }
        &--location3 {
            top: 10%;
            right:28%;
        }
        &--location4 {
            bottom: 33%;
            left: 56%;
        }
        &--location5 {
            bottom: 30%;
            right:13%;
        }
    }

    &__tip {
        color: $main-dark !important;
        transition: unset;
        &--location1{
            top:82%;
            left: 6%;
        }
        &--location2{
            top: 30%;
            left: 15%;
        }
        &--location3 {
            top: 35%;
            left: 70%;
        }
        &--location4 {
            top: 87%;
            left: 55%;
        }
        &--location5 {
            top: 89%;
            left: 95%;
        }
        display: none;
        transform: translate(-50%,-50%);
        position: absolute;
        padding: 30px 25px;
        text-align: left;
        z-index: 1;
        width: 370px;
        border-radius: 5px;
        border: 3px solid $turquoise;
        background-color: $white;
        h3 {
            font-size: 20px;
            font-family: $font-primary-bold;
            margin-bottom: 10px;
        }
        span {
            margin-right: 20px;
            font-size: 13px ;
            color: $dusty-gray;
            i {
                margin-right: 5px;
            }
        }
        p {
            color: $dove-gray;
            margin: 20px 0 0 0;
        }
    }

    .btns-display {
 
        &::before {
            color: $white;
            background-color: $turquoise;
        }
    }

}

