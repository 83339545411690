@include sectionTittleMiddle(filterable-tour);
.filterable-tour {
    @extend .col-4-tour;
    padding-bottom: 60px;

}
#filterable-posts {
    min-height: 467px;
}

.filterable-tour {
    &__categories {
        @include fontface($font-primary,17px,$dusty-gray,normal);
        text-align: center;
        margin-bottom: 35px;
        .active-filter {
            border-bottom: 2px solid $turquoise;
        }
        
        span {
            margin-bottom: -1px;
            cursor: pointer;
            transition: all .5s;
            border-bottom: 2px solid transparent;;
            padding: 4px 17px;
            display: inline-block;
        }
    }
}