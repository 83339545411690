//---------------------- travel review 1 - parner (index-1)-------

//************ disable mobile traveltip 1
@media(max-width:$sm){
    .travel-tip-1__area {
        display: none;
    }
    .video-popup-1 {
        margin-bottom: 65px;
    }
}
//************end  disable mobile traveltip 1

@media(max-width:$lg) {
    .partner__item {
        img {
            margin: 0 auto;
        }
    }
    .partner {
        margin: 30px 0 60px;
    }
} 


@media(max-width:$xl){
    .travel-review {
        padding: 0 30px 30px;
    }
}
@media(max-width:$lg){
    .travel-review {
        padding: 0 15px 15px;
    }
}
@media(max-width:$sm){
    #travel-review-area {
        .review-arrow-left {
            left: 5px;
        }
        .review-arrow-right {
            right: 5px;
        }
    }
}

//---------------------- travel review 2  -slider (index -3) -------
.travel-review-2__video {
    @media(max-width:$xl){
        height:400px;
    }
    @media(max-width:$lg){
        height:300px;
    }
}

@media (max-width:1200px) {
    .travel-review-2__item {
        padding:  0;
    }
}
@media (max-width:768px) {
    .travel-review-2-slider {
        margin-top: 50px;
    }
    .travel-review-2 {
        &__timesheet {
            img{
                margin-left: 15px;
            }
        }
        padding: 30px 0;
    }
        .travel-review-2__item {
        padding: 0;
    }
    .travel-review-2__item {
        margin-bottom: 30px;
    }
}

//---------------------- travel review 3  (index -3) -------
.travel-review-3 {
    @media (max-width:1024px) {
        &__expert-thumb {
            width: 80%;
            display: inline-block;
        }
        &__expert:last-child {
            .row {
                justify-content: center;
            }
        }
    }
    @media (max-width:$sm) {
        &__expert-thumb {
            width: 60%;
            margin-bottom: 30px;
        }
        &__expert {
            margin-bottom: 30px;
        }
    }
}




//--------------------------- travel-tip 1(index -1 )--------------


@media(max-width:1600px){
    
    .travel-tip-1 {
        &__tip {
            &--location1{
                top:85%;
                left: 20%;
            }
            &--location2{
                top: 47%;
                left: 35%;
            }
            &--location4 {
                top: 87%;
                left: 55%;
            }
            &--location5 {
                top: 89%;
                left: 83%;
            }
        }
    }
}
@media(max-width:$xl){
    .travel-tip-1 {
        &__tip {
            &--location1{
                top:90%;
                left: 20%;
            }
            &--location2{
                top: 52%;
                left: 35%;
            }
            &--location3 {
                top: 40%;
                left: 70%;
            }
            &--location4 {
                top: 90%;
                left: 55%;
            }
            &--location5 {
                top: 94%;
                left: 83%;
            }
        }
    }

}
@media(max-width:$lg){
    
    .travel-tip-1 {
        &__tip {
            padding: 15px;
            h3 {
                font-size: 15px;
            }
            span {
                margin-right: 10px;
            }
            p {
                font-size: 13px;
                margin: 0;
            }
            &--location1{
                top:85%;
                left: 25%;
            }
            &--location2{
                top: 48%;
                left: 35%;
            }
            &--location3 {
                top: 33%;

            }
            &--location4 {
                top: 85%;
            }
            &--location5 {
                top: 90%;
                left: 73%;
            }
        }

    }
}

@media(max-width:$md){
    .travel-tip-1 {
        &__btn {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 13px;
            &::before{
                line-height: 15px;
                font-size: 15px;
            }
        }
        padding-bottom: 0;
        &__tip {
            &--location1{
                top:92%;
                left: 40%;
            }
            &--location2{
                top: 56%;
                left: 35%;
            }
            &--location3 {
                top: 43%;

            }
            &--location4 {
                top: 92%;
            }
            &--location5 {
                top: 95%;
                left: 60%;
            }
        }
    } 
}
@media(max-width:$sm){
    .travel-tip-1 {
        &__tip {
            width: 100%;
            top: 115%;
            left: 50%;
        }
    }
}
@media(max-width:400px){
    .travel-tip-1 {
       
        &__tip {
           
            top: 145%;
        
        }
    }
}

//---------------------- travel tip 2  (index -5) -------

@media(max-width:$xl) {
    .travel-tip-2__thumb {
        width: 50%;
        height: 100%;
    }
    .travel-tip-2__item {
        height: auto;
        &__info {
            padding: 30px;
        }
    }
    .travel-tip-2 {
        .slick-dots {
            right: 48%;
        }
    }
}
@media(max-width:$lg) {
    .travel-tip-2__item {
        & > div {
            padding-left: 0;
        }
        &__info {
            text-align: center;
        }
        .travel-tip-2__thumb {
            position: unset;
            width: 70%;
            margin-bottom: 30px;
        }
        flex-direction: column;
    }
    .travel-tip-2 {
        padding-bottom: 80px;
        .slick-dots {
           bottom: 6%;
        }
    }

}
@media(max-width:$sm) {
    .travel-tip-2__item {
        &__info {
           padding: 15px;
           h3 {
               font-size: 15px;
           }
        }

    } 
}



//---------------------- instagram  (index -5) -------

@media(max-width:$md){
    .instagram{
        flex-flow: wrap;
        &__item {
            width: 33.333%;
        }
        
    }
    
}




//---------------------- travel review 4  (about us) -------

@media (max-width:$md){
    .aboutus-world-brg {
        padding-top: 35px;
        height: 700px;
        .travel-review__sub-tittle {
            margin-bottom: 50px;
        }
    }
}





//---------------------- our team  (about us) -------
@media(min-width:$lg) {
    .our-team .partner {
        margin: 90px 0;
    }
}
@media (max-width:$lg){
    .our-team {
        padding-top: 30px ;
        .travel-review__sub-tittle {
            margin-bottom: 0px;
        }
        .our-member {
            margin: 30px auto 0px auto;
        }
    }
}
@media (max-width:$sm){
    .our-team {

        .our-member {
            width: 70%;
            margin: 30px auto 0px auto;
        }
    }
}